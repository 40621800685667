import React from 'react';
import ChatSettings from './ChatSettings';
import TrainSettings from './TrainSettings';
import MemberSettings from './MemberSettings';
import DeleteCourse from './DeleteSettings';
import CourseSettings from './CourseSettings';

function Main({ course, toggle }) {
  let content;

  switch (toggle) {
    case 'train_course':
      content = <TrainSettings course={course} />;
      break;
    case 'course_settings':
      content = <CourseSettings course={course} />;
      break;
    case 'chat_settings':
      content = <ChatSettings course={course} />;
      break;
    case 'members':
      content = <MemberSettings course={course} />;
      break;
    case 'delete_course':
      content = <DeleteCourse course={course} />;
      break;
    default:
      content = (
        <div className="px-5">
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Doloribus eum nobis consectetur
          quod, minus, error nam dolor, voluptas dignissimos earum itaque voluptatum reiciendis
          provident reprehenderit suscipit possimus laborum ad ipsam.
        </div>
      );
      break;
  }

  return <div className="w-full px-3">{content}</div>;
}

export default Main;
