import { useState } from 'react';
import { ScrollArea } from '../../ui/scroll-area';
import { Button } from '../../ui/button';
import { Card } from '../../ui/card';
import { Info } from 'lucide-react';

const QuizPage = ({ questions }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [showExplanation, setShowExplanation] = useState(false);
  const [userAnswers, setUserAnswers] = useState([]);

  const currentQuestion = questions[currentIndex];

  const handleAnswerSelect = (option) => {
    setSelectedAnswer(selectedAnswer === option ? null : option);
  };

  const submitAnswer = () => {
    setDisabled(true);
    const isCorrect = selectedAnswer === currentQuestion.correctAnswer;
    setUserAnswers((prev) => [...prev, { ans: selectedAnswer, isCorrect }]);
    setShowExplanation(true);
  };

  const nextQuestion = () => {
    setDisabled(false);
    setShowExplanation(false);
    setSelectedAnswer(null);
    setCurrentIndex((prev) => prev + 1);
  };

  if (currentIndex >= questions.length) {
    return (
      <div className="flex flex-col items-center justify-center h-full bg-gray-50 dark:bg-gray-900">
        <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-100 mb-4">
          Quiz Completed! 🎉
        </h2>
        <div className="flex space-x-2">
          {userAnswers.map((answer, index) => (
            <div
              key={index}
              className={`w-6 h-6 rounded-full ${answer.isCorrect ? 'bg-green-500' : 'bg-red-500'}`}
              title={`Question ${index + 1}: ${answer.isCorrect ? 'Correct' : 'Incorrect'}`}
            />
          ))}
        </div>
        <pre className="mt-6 bg-gray-100 p-4 rounded text-left text-sm">
          {JSON.stringify(userAnswers, null, 2)}
        </pre>
      </div>
    );
  }

  return (
    <div className="h-full bg-gradient-to-br from-blue-50 to-white dark:from-brand-hover dark:to-brand rounded-2xl mt-2">
      <div className="flex flex-col md:flex-row h-full">
        {/* Question Section */}
        <div className="flex-1 flex flex-col items-center justify-center bg-blue-50/70 dark:bg-gray-800/80 p-6">
          <div className="flex space-x-2 mb-4">
            {userAnswers.map((answer, index) => (
              <div
                key={index}
                className={`w-4 h-4 rounded-full ${
                  answer.isCorrect ? 'bg-green-500' : 'bg-red-500'
                }`}
              />
            ))}
            {/* Placeholder for current question */}
            {Array.from({ length: questions.length - userAnswers.length }).map((_, index) => (
              <div key={index} className="w-4 h-4 rounded-full bg-gray-300" />
            ))}
          </div>
          <div className="max-w-2xl text-center space-y-6">
            <div className="flex justify-center items-center space-x-3">
              <span className="text-sm md:text-lg font-semibold text-indigo-600 dark:text-indigo-400 bg-blue-100 dark:bg-indigo-900 px-3 py-1 rounded-full">
                Question {currentIndex + 1} of {questions.length}
              </span>
            </div>
            <h2 className="text-lg md:text-4xl font-extralight text-gray-800 dark:text-gray-100 leading-tight tracking-wide">
              {currentQuestion.question}
            </h2>
          </div>
        </div>

        {/* Options Section */}
        <div className="h-full flex-1 bg-white dark:bg-brand-hover flex items-center justify-center p-8">
          <Card className="w-full max-w-lg p-8 shadow-2xl dark:bg-zinc-800 dark:border-zinc-700 space-y-6">
            <div className="space-y-4 p-2">
              {currentQuestion.options.map((option, index) => (
                <button
                  key={index}
                  disabled={disabled}
                  onClick={() => handleAnswerSelect(option)}
                  className={`w-full p-2 text-left rounded-xl border-2 transition-all transform ${
                    disabled
                      ? 'cursor-not-allowed opacity-50 bg-gray-200 dark:bg-gray-700 border-gray-300 dark:border-gray-600'
                      : 'hover:scale-[1.02]'
                  } ${
                    selectedAnswer === option
                      ? 'border-indigo-500 bg-indigo-50 dark:bg-indigo-700/50 ring-2 ring-indigo-500'
                      : 'border-gray-200 dark:border-gray-700 hover:border-indigo-300 hover:bg-indigo-50/30 dark:hover:bg-gray-700/30'
                  } ${
                    showExplanation && option === currentQuestion.correctAnswer
                      ? 'border-green-500 bg-green-50 dark:bg-green-900/20 ring-2 ring-green-500'
                      : ''
                  } ${
                    showExplanation &&
                    selectedAnswer === option &&
                    option !== currentQuestion.correctAnswer
                      ? 'border-red-500 bg-red-50 dark:bg-red-900/20 ring-2 ring-red-500'
                      : ''
                  }`}>
                  <span className="font-medium text-gray-800 dark:text-gray-100">
                    {index + 1}. {option}
                  </span>
                </button>
              ))}
            </div>

            {showExplanation && (
              <div
                className={`mt-3 p-4 rounded-lg ${
                  selectedAnswer === currentQuestion.correctAnswer
                    ? 'bg-green-50 border-green-200 dark:bg-green-900/20 dark:border-green-700'
                    : 'bg-red-50 border-red-200 dark:bg-red-900/20 dark:border-red-700'
                }`}>
                <h3
                  className={`text-lg font-semibold ${
                    selectedAnswer === currentQuestion.correctAnswer
                      ? 'text-green-700 dark:text-green-400'
                      : 'text-red-700 dark:text-red-400'
                  }`}>
                  {selectedAnswer === currentQuestion.correctAnswer ? 'Correct!' : 'Incorrect!'}
                </h3>
                <p className="text-sm text-gray-800 dark:text-gray-200">
                  {currentQuestion.explanation}
                </p>
              </div>
            )}

            <div className="mt-5 p-1">
              {showExplanation ? (
                <Button
                  onClick={nextQuestion}
                  className="w-full py-6 text-base bg-teal-600 hover:bg-teal-700 dark:bg-teal-500 dark:hover:bg-teal-600 rounded-xl transition-transform transform hover:scale-[1.02]">
                  Next Question
                </Button>
              ) : (
                <Button
                  onClick={submitAnswer}
                  disabled={!selectedAnswer}
                  className="w-full py-6 text-base bg-indigo-600 hover:bg-indigo-700 dark:bg-indigo-500 dark:hover:bg-indigo-600 rounded-xl transition-transform transform hover:scale-[1.02]">
                  Submit Answer
                </Button>
              )}
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default QuizPage;
