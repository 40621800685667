import React from 'react';
import SideBar from './SideBar';
import SidebarItem from './components/SidebarItem';
import TopBar from './TopBar';
import { CourseContextProvider } from '../context/useCourseContext';
import { ThemeProvider } from './theme-provider';
import {
  BookOpen,
  Briefcase,
  LifeBuoy,
  Home,
  Clipboard,
  GitGraph,
  LayoutList,
  Blocks,
} from 'lucide-react';

const Layout = ({ children }) => {
  return (
    <CourseContextProvider>
      <ThemeProvider defaultTheme="light">
        <div className="h-screen flex">
          <SideBar>
            <SidebarItem icon={<Home size={18} />} text="Home" to="/home" />
            <SidebarItem icon={<BookOpen size={18} />} text="Courses" to="/courses" />
            {/* <SidebarItem icon={<Clipboard size={18} />} text="Quiz" to="/quiz" /> */}
            <SidebarItem icon={<LayoutList size={18} />} text="Algo Sheet" to="/dsa" />
            {/* <SidebarItem icon={<GitGraph size={18} />} text="DSA" to="/dsa" /> */}
            {/* <SidebarItem icon={<Blocks size={18} />} text="Baskets" to="/dev" /> */}
            <SidebarItem icon={<Briefcase size={18} />} text="Stories" to="/blogs" alert={true} />
            <SidebarItem icon={<LifeBuoy size={18} />} text="Help" to="/help" />
          </SideBar>

          <div className="flex-1 flex flex-col h-screen">
            <TopBar />
            {children}
            <div className="animated-gradient"></div>
          </div>
        </div>
      </ThemeProvider>
    </CourseContextProvider>
  );
};

export default Layout;
