import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/icon.jpg';

function Footer() {
  return (
    <footer className="bg-brand/40 rounded-lg shadow m-4">
      <div className="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
        <div className="flex items-center justify-start gap-2">
          <Link to="/">
            <img
              className="hover:border-2 hover:rounded border-gray-300"
              src={logo}
              style={{ height: '38px', borderRadius: 10 }}
              alt="logo"
            />
          </Link>
          <span className="text-sm sm:text-center ">
            © 2024{' '}
            <Link to="/" className="hover:underline">
              Brogrammers™
            </Link>
            . All Rights Reserved.
          </span>
        </div>

        <ul className="flex flex-wrap items-center mt-3 text-sm font-medium sm:mt-0">
          <li>
            <Link to="/privacy-policies" className="hover:underline me-4 md:me-6">
              Privacy Policy
            </Link>
          </li>
          <li>
            <Link to="/terms-conditions" className="hover:underline me-4 md:me-6">
              Terms & Conditions
            </Link>
          </li>
          <li>
            <Link to={'/pricing'} className="hover:underline me-4 md:me-6">
              Pricing
            </Link>
          </li>
          <li>
            <Link to={'/return-refund'} className="hover:underline me-4 md:me-6">
              Return & Refund
            </Link>
          </li>
          <li>
            <Link to={'/help'} className="hover:underline">
              Contact
            </Link>
          </li>
        </ul>
      </div>
    </footer>
  );
}

export default Footer;
