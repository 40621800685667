import { Label } from '../../components/ui/label';
import { Input } from '../../components/ui/input';
import { Textarea } from '../../components/ui/textarea';
import { Button } from '../../components/ui/button';
import { ScrollArea } from '../../components/ui/scroll-area';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '../../components/ui/select';
import React, { useState } from 'react';
import { axios } from '../../services';

function Create() {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [projects, setProjects] = useState([
    { name: '', complexity: 'easy', et: '', code: '', preReq: [''] },
  ]);

  const handleAddProject = () => {
    setProjects([...projects, { name: '', complexity: 'easy', et: '', code: '', preReq: [''] }]);
  };

  const handleRemoveProject = (index) => {
    const updatedProjects = projects.filter((_, i) => i !== index);
    setProjects(updatedProjects);
  };

  const handleProjectChange = (index, field, value) => {
    const updatedProjects = projects.map((project, i) =>
      i === index ? { ...project, [field]: value } : project
    );
    setProjects(updatedProjects);
  };

  const handleAddPreReq = (projectIndex) => {
    const updatedProjects = projects.map((project, i) =>
      i === projectIndex ? { ...project, preReq: [...project.preReq, ''] } : project
    );
    setProjects(updatedProjects);
  };

  const handleRemovePreReq = (projectIndex, preReqIndex) => {
    const updatedProjects = projects.map((project, i) =>
      i === projectIndex
        ? {
            ...project,
            preReq: project.preReq.filter((_, j) => j !== preReqIndex),
          }
        : project
    );
    setProjects(updatedProjects);
  };

  const handlePreReqChange = (projectIndex, preReqIndex, value) => {
    const updatedProjects = projects.map((project, i) =>
      i === projectIndex
        ? {
            ...project,
            preReq: project.preReq.map((pre, j) => (j === preReqIndex ? value : pre)),
          }
        : project
    );
    setProjects(updatedProjects);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('/basket/create', {
        title,
        description,
        projects,
      });
      alert('Success');
    } catch (error) {
      alert(error);
    }
  };

  return (
    <ScrollArea className="p-2 sm:p-8 h-full">
      <div className="bg-muted/40 mt-5 p-4 sm:p-6 shadow-md rounded-lg ">
        <h1 className="text-center text-2xl font-bold mb-6">Create Project Basket</h1>
        <form onSubmit={handleSubmit}>
          <div className="mb-4 p-1">
            <Label className="block mb-2">Title</Label>
            <Input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
              className=""
            />
          </div>
          <div className="mb-4 p-1">
            <Label className="block mb-2">Description</Label>
            <Textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </div>
          <h2 className="text-xl font-semibold mb-4">Projects</h2>
          {projects.map((project, index) => (
            <div key={index} className="mb-6 p-4 border rounded-lg">
              <h3 className="text-lg font-semibold mb-4">Project {index + 1}</h3>
              <div className="mb-4 p-1">
                <Label className="block mb-2">Name</Label>
                <Input
                  type="text"
                  value={project.name}
                  onChange={(e) => handleProjectChange(index, 'name', e.target.value)}
                  required
                />
              </div>
              <div className="mb-4 p-1">
                <Label className="block mb-2">Complexity</Label>
                <Select
                  defaultValue="easy"
                  onValueChange={(value) => handleProjectChange(index, 'complexity', value)}>
                  <SelectTrigger>
                    <SelectValue placeholder="Select Complexity" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectLabel>Complexity</SelectLabel>
                      <SelectItem value="easy">Easy</SelectItem>
                      <SelectItem value="medium">Medium</SelectItem>
                      <SelectItem value="hard">Hard</SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>

              <div className="mb-4 p-1">
                <Label className="block mb-2">Estimated Time</Label>
                <Input
                  type="text"
                  value={project.et}
                  onChange={(e) => handleProjectChange(index, 'et', e.target.value)}
                  required
                />
              </div>
              <div className="mb-4 p-1">
                <Label className="block mb-2">Code</Label>
                <Input
                  type="text"
                  value={project.code}
                  onChange={(e) => handleProjectChange(index, 'code', e.target.value)}
                  required
                />
              </div>
              <div className="mb-4 p-1">
                <Label className="block mb-2">Pre-requisites</Label>
                {project.preReq.map((pre, preIndex) => (
                  <div key={preIndex} className="pb-2 flex items-center">
                    <Input
                      type="text"
                      value={pre}
                      onChange={(e) => handlePreReqChange(index, preIndex, e.target.value)}
                      required
                    />
                    <Button
                      type="button"
                      onClick={() => handleRemovePreReq(index, preIndex)}
                      className="ml-2 bg-red-500 hover:bg-red-700">
                      Remove
                    </Button>
                  </div>
                ))}
                <Button
                  type="button"
                  onClick={() => handleAddPreReq(index)}
                  className="mt-2 bg-green-500 hover:bg-green-700">
                  Add Pre-requisite
                </Button>
              </div>
              <Button
                onClick={() => handleRemoveProject(index)}
                className="mt-4 bg-red-500 hover:bg-red-700">
                Remove Project
              </Button>
            </div>
          ))}
          <Button
            type="button"
            onClick={handleAddProject}
            className="mt-4 bg-green-500 hover:bg-green-700 mr-2">
            Add Project
          </Button>
          <Button
            type="submit"
            className="mt-6 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-700">
            Create Project Basket
          </Button>
        </form>
      </div>
    </ScrollArea>
  );
}

export default Create;
