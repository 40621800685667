import React, { createContext, useContext, useState, useCallback } from 'react';
import axios from '../services/axios';

const BuddyContext = createContext();

export function BuddyProvider({ children }) {
  const [code, setCode] = useState('');
  const [selectedTab, setSelectedTab] = useState('problem');
  const [problemDetails, setProblemDetails] = useState(null);
  const [explanation, setExplanation] = useState(null);
  const [analysis, setAnalysis] = useState(null);
  const [loading, setLoading] = useState(false);

  const getProblem = useCallback(async (id) => {
    try {
      const { data } = await axios.get(`/aibuddy/${id}`);
      setProblemDetails(data.data);
    } catch (error) {
      alert('error fetching problem');
    }
  }, []);

  const generateExplanation = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await axios.post('/aibuddy/explain', {
        data: problemDetails?.statement,
      });
      setExplanation(data.data);
    } catch (error) {
      console.log(
        "This code appears to be implementing a binary search algorithm. Let's break it down..."
      );
    } finally {
      setLoading(false);
    }
  }, [problemDetails]);

  const generateHint = useCallback(() => {
    return 'Consider what happens when the array is empty. Have you handled all edge cases?';
  }, []);

  const analyzeCode = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await axios.post('/aibuddy/analyze', { data: code });
      setAnalysis(data.data);
    } catch (error) {
      console.log('Error fetching complexity', error);
    } finally {
      setLoading(false);
    }
  }, [code]);

  const generateFeedback = useCallback(() => {
    return {
      overall: 'Good implementation with room for improvement',
      strengths: ['Correct algorithm implementation', 'Good basic structure'],
      improvements: ['Add comments', 'Consider edge cases'],
    };
  }, []);

  const contextValue = {
    loading,
    getProblem,
    explanation,
    code,
    analysis,
    setCode,
    selectedTab,
    setSelectedTab,
    analyzeCode,
    generateFeedback,
    generateHint,
    generateExplanation,
    problemDetails,
  };

  return <BuddyContext.Provider value={contextValue}>{children}</BuddyContext.Provider>;
}

export const useBuddy = () => {
  const context = useContext(BuddyContext);
  if (!context) {
    throw new Error('useBuddy must be used within a BuddyProvider');
  }
  return context;
};
