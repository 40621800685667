import React from 'react';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../ui/select';
import { Input } from '../../ui/input';
import { Label } from '../../ui/label';
import { useTranslation } from 'react-i18next';

function InputFields({ courseDetails, handleInputChange, submitting }) {
  const { t } = useTranslation();

  return (
    <div>
      <div className="grid md:grid-cols-2 gap-2 px-4">
        {/* Course Title Input */}
        <div className="p-2 space-y-1">
          <Label htmlFor="course-title">{t('homePage.inputFields.labels.courseTitle')}</Label>
          <Input
            id="course-title"
            type="text"
            name="title"
            value={courseDetails.title}
            onChange={handleInputChange}
            placeholder={t('homePage.inputFields.placeholders.courseTitle')}
            required
            disabled={submitting}
          />
        </div>

        {/* Course Level Select */}
        <div className="p-2 space-y-1">
          <Label htmlFor="course-level">{t('homePage.inputFields.labels.courseLevel')}</Label>
          <Select
            name="level"
            value={courseDetails.level}
            onValueChange={(value) =>
              handleInputChange({
                target: { name: 'level', value },
              })
            }>
            <SelectTrigger>
              <SelectValue placeholder={t('homePage.inputFields.placeholders.courseLevel')} />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="beginner" className="cursor-pointer">
                {t('homePage.inputFields.options.level.beginner')}
              </SelectItem>
              <SelectItem value="intermediate" className="cursor-pointer">
                {t('homePage.inputFields.options.level.intermediate')}
              </SelectItem>
              <SelectItem value="advanced" className="cursor-pointer">
                {t('homePage.inputFields.options.level.advanced')}
              </SelectItem>
            </SelectContent>
          </Select>
        </div>

        {/* Course Depth Select */}
        <div className="p-2 space-y-1">
          <Label htmlFor="course-depth">{t('homePage.inputFields.labels.courseDepth')}</Label>
          <Select
            disabled={submitting}
            name="depth"
            value={courseDetails.depth}
            onValueChange={(value) =>
              handleInputChange({
                target: { name: 'depth', value },
              })
            }>
            <SelectTrigger>
              <SelectValue placeholder={t('homePage.inputFields.placeholders.courseDepth')} />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="introductory" className="cursor-pointer">
                {t('homePage.inputFields.options.depth.introductory')}
              </SelectItem>
              <SelectItem value="comprehensive" className="cursor-pointer">
                {t('homePage.inputFields.options.depth.comprehensive')}
              </SelectItem>
              <SelectItem value="specialized" className="cursor-pointer">
                {t('homePage.inputFields.options.depth.specialized')}
              </SelectItem>
            </SelectContent>
          </Select>
        </div>

        {/* Language Select */}
        <div className="p-2 space-y-1">
          <Label htmlFor="course-language">{t('homePage.inputFields.labels.courseLanguage')}</Label>
          <Select
            disabled={submitting}
            name="language"
            value={courseDetails.language}
            onValueChange={(value) =>
              handleInputChange({
                target: { name: 'language', value },
              })
            }>
            <SelectTrigger>
              <SelectValue placeholder={t('homePage.inputFields.placeholders.courseLanguage')} />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="english" className="cursor-pointer">
                {t('homePage.inputFields.options.language.english')}
              </SelectItem>
              <SelectItem value="korean" className="cursor-pointer">
                {t('homePage.inputFields.options.language.korean')}
              </SelectItem>
              <SelectItem value="french" className="cursor-pointer">
                {t('homePage.inputFields.options.language.french')}
              </SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>
    </div>
  );
}

export default InputFields;
