import React, { useState } from 'react';
import { Loader2, Upload, Book, Youtube } from 'lucide-react';
import { Button } from '../../ui/button';
import { Input } from '../../ui/input';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from '../../ui/card';
import { Alert, AlertDescription } from '../../ui/alert';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../ui/tabs';
import { axios } from '../../../services';

const CreateFlashCard = ({ course, setFlashCard }) => {
  const [activeTab, setActiveTab] = useState('youtube');
  const [topic, setTopic] = useState('');
  const [ytLink, setYtLink] = useState('');
  const [files, setFiles] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const validateForm = () => {
    if (activeTab === 'topic' && !topic) {
      setError('Please enter a topic.');
      return false;
    }
    if (
      activeTab === 'youtube' &&
      (!ytLink || (!ytLink.includes('youtube.com') && !ytLink.includes('youtu.be')))
    ) {
      setError('Please enter a valid YouTube URL.');
      return false;
    }
    if (activeTab === 'document' && !files) {
      setError('Please upload a document.');
      return false;
    }
    return true;
  };

  const createQnA = async () => {
    if (!validateForm()) return;

    try {
      setLoading(true);
      setError('');

      let requestData;
      let config = {};

      if (activeTab === 'document') {
        // Use FormData for file uploads
        const formData = new FormData();
        formData.append('userId', course?.userId);
        formData.append('title', course?.title);
        formData.append('input_type', 'doc');
        formData.append('output_type', 'flashcard');
        formData.append('files', files);

        requestData = formData;
        config.headers = {
          'Content-Type': 'multipart/form-data',
        };
      } else {
        // Use JSON for other inputs
        requestData = {
          topic,
          userId: course?.userId,
          title: course?.title,
          input_type: activeTab === 'youtube' ? 'yt_link' : 'topics',
          output_type: 'flashcard',
          ...(activeTab === 'youtube' && { yt_link: ytLink }),
        };
        config.headers = {
          'Content-Type': 'application/json',
        };
      }

      const { data } = await axios.post(`/practice/flash/${course._id}`, requestData, config);

      setFlashCard((prevFlashCards) => [data.data, ...prevFlashCards]);
      setSuccess(true);
      resetForm();

      setTimeout(() => setSuccess(false), 3000);
    } catch (error) {
      setError('Failed to create flash card. Please try again.');
      console.error('Error details:', error);
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setTopic('');
    setYtLink('');
    setFiles(null);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.size > 10 * 1024 * 1024) {
      setError('File size should be less than 10MB.');
      return;
    }
    setFiles(selectedFile);
    setTopic(null);
    setYtLink(null);
  };

  return (
    <div className="flex items-center justify-center p-4">
      <Card className="w-full max-w-lg shadow-lg rounded-2xl bg-muted/40">
        <CardHeader className="space-y-1">
          <CardTitle className="text-2xl font-bold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
            Create Flash Card
          </CardTitle>
          <CardDescription>
            Generate flash cards from topics, YouTube videos, or documents.
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-4">
          {error && (
            <Alert variant="destructive" className="mb-4">
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}
          {success && (
            <Alert className="mb-4 bg-green-50 text-green-800 border-green-200">
              <AlertDescription>Flash card created successfully!</AlertDescription>
            </Alert>
          )}

          <Tabs value={activeTab} onValueChange={setActiveTab} className="w-full">
            <TabsList className="grid grid-cols-3 mb-4">
              <TabsTrigger value="youtube" className="flex items-center gap-2">
                <Youtube className="w-4 h-4" />
                YouTube
              </TabsTrigger>
              <TabsTrigger value="document" className="flex items-center gap-2">
                <Upload className="w-4 h-4" />
                Document
              </TabsTrigger>
              <TabsTrigger value="topic" className="flex items-center gap-2">
                <Book className="w-4 h-4" />
                Topic
              </TabsTrigger>
            </TabsList>

            <TabsContent value="topic">
              <Input
                id="topic-input"
                type="text"
                value={topic}
                onChange={(e) => {
                  setTopic(e.target.value);
                  setYtLink(null);
                  setFiles(null);
                }}
                placeholder="Enter the topic for your flash cards"
              />
            </TabsContent>

            <TabsContent value="youtube">
              <Input
                type="text"
                value={ytLink}
                onChange={(e) => {
                  setYtLink(e.target.value);
                  setFiles(null);
                  setTopic(null);
                }}
                placeholder="Paste YouTube video URL"
              />
            </TabsContent>

            <TabsContent value="document">
              <Input type="file" onChange={handleFileChange} accept=".pdf,.txt,.md" />
              {files && <p className="text-sm mt-2 text-center">{files.name}</p>}
            </TabsContent>
          </Tabs>

          <Button
            onClick={createQnA}
            disabled={loading}
            className="w-full h-12 bg-gradient-to-r from-blue-600 to-purple-600 hover:from-blue-700 hover:to-purple-700 text-white font-semibold rounded-lg transition-all transform hover:scale-[1.02] disabled:opacity-50">
            {loading ? (
              <div className="flex items-center justify-center space-x-2">
                <Loader2 className="w-5 h-5 animate-spin" />
                <span>Creating...</span>
              </div>
            ) : (
              <span>Create Flash Card ✨</span>
            )}
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};

export default CreateFlashCard;
