import React, { useMemo } from 'react';
import { Main, RecentlyViewed, AdminCourses, Features } from '../components/home';
import { ScrollArea } from '../components/ui/scroll-area';

const Home = () => {
  const recentlyViewedComponent = useMemo(() => <RecentlyViewed />, []);
  const myCoursesComponent = useMemo(() => <AdminCourses />, []);

  return (
    <ScrollArea className="flex-1">
      <div className="container px-1 sm:px-7 pb-4">
        <div className="pb-14">
          <Features />
          <Main />
        </div>
        {recentlyViewedComponent}
        {myCoursesComponent}
      </div>
    </ScrollArea>
  );
};

export default React.memo(Home);
