import React, { useState } from 'react';
import YouTube from 'react-youtube';
import { Skeleton } from '../ui/skeleton';

const YoutubeVids = ({ videoIds }) => {
  const [loadedVideos, setLoadedVideos] = useState({});

  const handleVideoLoad = (id) => {
    setLoadedVideos((prev) => ({ ...prev, [id]: true }));
  };

  if (!videoIds?.length) {
    return (
      <div className="mt-2">
        <h1 className="mb-2 font-bold text-lg">Suggested Videos:</h1>
        <p className="text-gray-600">No videos available.</p>
      </div>
    );
  }

  return (
    <div>
      <div className="grid grid-cols-10 md:grid-cols-2 lg:grid-cols-4 gap-2 pb-1 px-1">
        {videoIds.map((id) => (
          <div
            key={id}
            className="relative w-full h-0 pb-[56.25%] rounded-lg overflow-hidden shadow-md">
            {!loadedVideos[id] && (
              <Skeleton className="absolute top-0 left-0 w-full h-full rounded-xl" />
            )}
            <YouTube
              videoId={id}
              opts={{
                width: '100%',
                height: '100%',
                playerVars: { autoplay: 0 },
              }}
              className={`absolute top-0 left-0 w-full h-full ${
                loadedVideos[id] ? 'opacity-100' : 'opacity-0'
              }`}
              title={`YouTube Video ${id}`}
              onReady={() => handleVideoLoad(id)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default YoutubeVids;
