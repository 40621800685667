import { Main, QuizStarter } from './components/dash';

const Dashboard = () => {
  return (
    <div>
      <div className="flex items-center justify-center min-h-screen -mt-6">
        <div className="flex w-full max-w-7xl px-4">
          <div className="w-1/2">
            <Main />
          </div>
          <div className="w-1/2">
            <QuizStarter />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
