import React, { useState, useEffect } from 'react';
import { axios } from '../../../services';
import { useCourse } from '../../../context/useCourseContext';
import { ScrollArea } from '../../ui/scroll-area';
import QuizItem from './QuizItem';
import CreateFlashCard from './CreateFlashCard';

function FlashCard({ course }) {
  const { isAdmin } = useCourse();
  const [flashCards, setFlashCard] = useState([]);

  const getQnA = async () => {
    try {
      const { data } = await axios.get(`/practice/flash/${course._id}`);
      setFlashCard(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getQnA();
  }, []);

  return (
    <ScrollArea className="h-full">
      <div className="space-y-8">
        {/* <div>{isAdmin && <CreateFlashCard course={course} setFlashCard={setFlashCard} />}</div> */}
        <div>{<CreateFlashCard course={course} setFlashCard={setFlashCard} />}</div>
        <div className="grid grid-cols sm:grid-cols-2 md:grid-cols-3 px-1 gap-2 pb-10">
          {flashCards?.map((flashCard) => (
            <div className="p-2" key={flashCard._id}>
              <QuizItem flashCard={flashCard} />
            </div>
          ))}
        </div>
      </div>
    </ScrollArea>
  );
}

export default FlashCard;
