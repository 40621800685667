import React, { useState } from 'react';
import { Button } from './ui/button';
import { Input } from './ui/input';
import useDrivePicker from 'react-google-drive-picker';

function GoogleDrive({ field, onFileSelect }) {
  const [openPicker] = useDrivePicker();
  const [title, setTitle] = useState('');
  const [error, setError] = useState('');

  const handleOpenPicker = () => {
    openPicker({
      clientId: '514157576144-h2193ttedlpsosmnslj7hgl744c84hs0.apps.googleusercontent.com',
      developerKey: 'AIzaSyD9d0lGoMRe18iSM2FQFHkd-6tsY5IuefU',
      viewId: 'DOCS',
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: false,

      callbackFunction: (data) => {
        if (data.action === 'picked' && data.docs.length > 0) {
          const fileUrl = data.docs[0].id;
          console.log(data);
          onFileSelect({ title, link: fileUrl });
          setError('');
        }
      },
    });
  };

  return (
    <div className="flex my-2 gap-2">
      {field && (
        <Input
          type="text"
          placeholder="Enter file title"
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
            setError('');
          }}
        />
      )}

      {error && <p className="text-red-500">{error}</p>}
      <Button variant="brand" onClick={handleOpenPicker}>
        Open Picker
      </Button>
    </div>
  );
}

export default GoogleDrive;
