import React from 'react';
import { BlogHeader, FilterButtons, Latest } from '../components/placement';
import { PlacementProvider } from '../context/usePlacementContext';
import { ScrollArea } from '../components/ui/scroll-area';
import { useCourse } from '../context/useCourseContext';
import Pricing from '../components/Pricing';

function Placement() {
  const { isBro } = useCourse();
  return (
    <PlacementProvider>
      <ScrollArea className="h-full px-2 sm:px-7 pb-3">
        <div className="md:px-20">
          <BlogHeader />
          <FilterButtons />
        </div>
        {!isBro ? (
          <div className="md:px-20">
            <Latest />
          </div>
        ) : (
          <Pricing />
        )}
      </ScrollArea>
    </PlacementProvider>
  );
}

export default Placement;
