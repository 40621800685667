import React, { useState } from 'react';
import { UploadCloud, X, FileIcon, HelpCircle } from 'lucide-react';
import { Switch } from '../../ui/switch';
import { Input } from '../../ui/input';
import { Label } from '../../ui/label';
import { Button } from '../../ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '../../ui/card';
import axios from '../../../services/axios';

const TrainCourse = ({ course }) => {
  const [files, setFiles] = useState([]);
  const [submitting, setSubmitting] = useState(false);

  const handleInputChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFiles([selectedFile]);
    }
  };

  const handleTraining = async () => {
    if (files.length === 0) {
      alert('Please upload a file before starting training.');
      return;
    }

    setSubmitting(true);

    try {
      const formData = new FormData();

      console.log(course.userId, course.title, files[0]);

      formData.append('userId', course.userId);
      formData.append('title', course.title);
      formData.append('files', files[0]);

      console.log(formData);

      const { data } = await axios.post(`/course/train/${course._id}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        timeout: 90000,
        retries: 1,
      });
      console.log(data);
      setFiles([]);
    } catch (error) {
      console.error(error);
      alert(`Error training: ${error.message}`);
    } finally {
      setSubmitting(false);
    }
  };

  const removeFile = (index) => {
    setFiles(files.filter((_, i) => i !== index));
  };

  return (
    <>
      <Card className="w-full">
        <CardHeader>
          <CardTitle>Train Course</CardTitle>
          <CardDescription>Manage course training settings</CardDescription>
        </CardHeader>
        <CardContent className="space-y-6">
          <div className="flex items-center justify-between">
            <div className="space-y-0.5">
              <div className="font-medium">Dynamic Learning</div>
              <div className="text-sm text-muted-foreground">
                Update knowledge base from conversations and flashcards
              </div>
            </div>
            <Switch />
          </div>
          <div className="flex items-center justify-between">
            <div className="space-y-0.5">
              <div className="font-medium">Document Training</div>
              <div className="text-sm text-muted-foreground">
                Upload documents to enhance the AI's knowledge
              </div>
            </div>
          </div>
          <div className="group px-2">
            <div
              className={`border-2 border-dashed rounded-xl p-8 text-center 
                hover:border-primary group-hover:bg-muted/50 group-hover:shadow-sm group-hover:transition-colors 
                ${submitting ? 'opacity-50 pointer-events-none' : ''}`}>
              <Input
                disabled={submitting}
                type="file"
                id="file-upload"
                className="hidden"
                multiple={false}
                accept=".pdf"
                onChange={handleInputChange}
              />
              <Label htmlFor="file-upload" className="cursor-pointer">
                <div className="flex flex-col items-center gap-4">
                  <UploadCloud className="h-10 w-10 text-primary/40 group-hover:text-primary" />
                  <span className="font-medium text-muted-foreground pb-2">
                    Upload a PDF file for training
                  </span>
                </div>
              </Label>
            </div>
          </div>
          <div className="space-y-2">
            {files.map((file, index) => (
              <div
                key={index}
                className="flex items-center justify-between p-2 bg-muted rounded-lg">
                <div className="flex items-center gap-3">
                  <FileIcon className="h-5 w-5" />
                  <div>
                    <p className="text-sm font-medium">{file.name}</p>
                    <p className="text-xs text-muted-foreground">
                      {(file.size / 1024 / 1024).toFixed(2)} MB
                    </p>
                  </div>
                </div>
                <Button
                  variant="outline"
                  size="icon"
                  className="rounded-full"
                  onClick={() => removeFile(index)}>
                  <X className="h-4 w-4" />
                </Button>
              </div>
            ))}
          </div>
          <Button
            className="w-full"
            onClick={handleTraining}
            disabled={submitting || files.length === 0}>
            {submitting ? 'Submitting...' : 'Start Training'}
          </Button>
        </CardContent>
      </Card>
      <div className="flex items-start gap-2 px-2 mt-2 border-2 border-dashed rounded-lg py-2 bg-muted/40">
        <HelpCircle className="h-6 w-6 text-muted-foreground mt-1 flex-shrink-0" />
        <p className="text-sm text-muted-foreground">
          To verify the training results, try asking the chatbot a question about your document
          before and after training to see the difference in responses.
        </p>
      </div>
    </>
  );
};

export default TrainCourse;
