import React from 'react';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from './ui/card';
import { NotebookPen, Sparkles, Boxes, ChevronsRight, Clock } from 'lucide-react';
import { Button } from './ui/button';
import { Link } from 'react-router-dom';
import { timeAgo } from '../services';
import { useCourse } from '../context/useCourseContext';

function CourseCard({ course, viewed, avatars = [] }) {
  const lastSeen = timeAgo(viewed);
  const { setCourse, clearSelectedTopic, setSelectedToggle } = useCourse();

  const cardButtons = [
    { icon: NotebookPen, text: 'Doc chat', toggle: 'docchat' },
    { icon: Boxes, text: 'Flash Cards', toggle: 'flashcard' },
    { icon: Sparkles, text: 'Summary', toggle: 'summary' },
  ];

  return (
    <Card className="relative bg-gradient-to-br from-muted/40 to-muted/20 cursor-pointer h-full hover:shadow-lg transition-all duration-300 overflow-hidden group backdrop-blur-2xl">
      {/* Card Header */}
      <CardHeader className="pb-2 relative z-10">
        <CardTitle className="truncate pb-1 text-xl font-bold bg-clip-text">
          {course?.title}
        </CardTitle>
        {viewed && (
          <CardDescription className="flex items-center text-muted-foreground">
            <Clock size={14} className="mr-1" />
            Viewed {lastSeen}
          </CardDescription>
        )}
      </CardHeader>

      {/* Card Content */}
      <CardContent className="px-4 space-y-2 relative z-10">
        <div className="flex items-center space-x-2">
          {avatars.slice(0, 3).map((avatar, index) => (
            <img
              key={index}
              src={avatar}
              alt="User avatar"
              className="w-8 h-8 rounded-full border-2 border-muted"
            />
          ))}
          {avatars.length > 3 && (
            <span className="text-sm text-muted-foreground">+{avatars.length - 3} more</span>
          )}
        </div>

        <div>
          {cardButtons.map((button, index) => (
            <Button
              key={index}
              className="w-full justify-start text-foreground bg-zinc-100 dark:bg-zinc-900 hover:bg-zinc-200 dark:hover:bg-zinc-600 transition-colors duration-200 rounded-lg mb-1"
              onClick={() => {
                setCourse(course);
                clearSelectedTopic();
                setSelectedToggle(button.toggle);
              }}
              asChild>
              <Link to={`/courses/${course?.title}?id=${course?._id}`}>
                <button.icon size={18} className="mr-2" />
                {button.text}
              </Link>
            </Button>
          ))}
        </div>
      </CardContent>

      {/* Card Footer */}
      <CardFooter className="p-4 pt-0 relative z-10">
        <Button
          variant="outline"
          className="w-full justify-between bg-brand text-background hover:bg-brand-hover hover:text-primary-foreground transition-colors duration-200 rounded-lg"
          onClick={() => {
            clearSelectedTopic();
            setCourse(course);
          }}
          asChild>
          <Link to={`/courses/${course?.title}?id=${course?._id}`}>
            <span className="font-semibold">Explore Course</span>
            <ChevronsRight
              size={18}
              className="ml-2 transition-transform duration-200 group-hover:translate-x-1"
            />
          </Link>
        </Button>
      </CardFooter>
    </Card>
  );
}

export default CourseCard;
