import React, { useState } from 'react';
import { Loader2, Upload, Book, Youtube } from 'lucide-react';
import { Button } from '../../ui/button';
import { Input } from '../../ui/input';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from '../../ui/card';
import { Alert, AlertDescription } from '../../ui/alert';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '../../ui/tabs';
import { axios } from '../../../services';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '../../ui/select';

const CreateQuiz = ({ course, setQuiz }) => {
  const [topic, setTopic] = useState('');
  const [ytLink, setYtLink] = useState('');
  const [files, setFile] = useState(null);
  const [questionCount, setQuestionCount] = useState(10);
  const [difficulty, setDifficulty] = useState('medium');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [activeTab, setActiveTab] = useState('doc');

  const getInputType = () => {
    if (ytLink) return 'yt_link';
    if (files) return 'doc';
    return 'topics';
  };

  const validateForm = () => {
    if (!topic && !ytLink && !files) {
      setError('Atleast one of the option is needed');
      return false;
    }

    if (ytLink && !ytLink.includes('youtube.com') && !ytLink.includes('youtu.be')) {
      setError('Please enter a valid YouTube URL');
      return false;
    }

    if (questionCount && (isNaN(questionCount) || questionCount < 1 || questionCount > 50)) {
      setError('Please enter a valid number of questions (1-50)');
      return false;
    }

    return true;
  };

  const createQnA = async () => {
    if (!validateForm()) return;

    try {
      setLoading(true);
      setError('');

      let config = {};

      const formData = new FormData();
      formData.append('topic', topic);
      formData.append('userId', course?.userId);
      formData.append('title', course?.title);
      formData.append('input_type', getInputType());
      formData.append('output_type', 'mcq');
      formData.append('difficulty', difficulty);

      if (ytLink) formData.append('yt_link', ytLink);
      if (questionCount) formData.append('length', questionCount > 50 ? 50 : questionCount);

      if (files) {
        formData.append('files', files);
        config.headers = {
          'Content-Type': 'multipart/form-data',
        };
      } else {
        config.headers = {
          'Content-Type': 'application/json',
        };
      }
      const body = formData;
      const { data } = await axios.post(`/practice/quiz/${course?._id}`, body, config);

      setQuiz((prevQuizzes) => [data.data, ...prevQuizzes]);
      setSuccess(true);
      resetForm();

      setTimeout(() => setSuccess(false), 3000);
    } catch (error) {
      setError('Failed to create quiz. Please try again.');
      console.error('Error details:', error);
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setTopic('');
    setYtLink('');
    setFile(null);
    setQuestionCount('');
    setActiveTab('topic');
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.size > 5 * 1024 * 1024) {
      setError('File size should be less than 5MB');
      return;
    }
    setFile(selectedFile);
    setYtLink(null);
    setTopic(null);
    setActiveTab('doc');
  };

  return (
    <div className="flex items-center justify-center p-4">
      <Card className="w-full max-w-lg shadow-lg rounded-2xl bg-gradient-to-br ">
        <CardHeader className="space-y-1">
          <CardTitle className="text-2xl font-bold bg-gradient-to-r from-orange-600 to-purple-600 bg-clip-text text-transparent">
            Create Interactive Quiz
          </CardTitle>
          <CardDescription>
            Generate engaging multiple-choice questions from various sources
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-4">
          {error && (
            <Alert variant="destructive" className="mb-4">
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}

          {success && (
            <Alert className="mb-4 bg-green-50 text-green-800 border-green-200">
              <AlertDescription>Quiz created successfully!</AlertDescription>
            </Alert>
          )}

          <Tabs value={activeTab} onValueChange={setActiveTab} className="w-full">
            <TabsList className="grid grid-cols-3 mb-4">
              <TabsTrigger disabled value="youtube" className="flex items-center gap-2">
                <Youtube className="w-4 h-4" />
                YouTube
              </TabsTrigger>
              <TabsTrigger value="doc" className="flex items-center gap-2">
                <Upload className="w-4 h-4" />
                Document
              </TabsTrigger>
              <TabsTrigger value="topic" className="flex items-center gap-2">
                <Book className="w-4 h-4" />
                Topic
              </TabsTrigger>
            </TabsList>

            <TabsContent value="topic" className="space-y-4 p-1">
              <Input
                value={topic}
                onChange={(e) => {
                  setTopic(e.target.value);
                  setYtLink(null);
                  setFile(null);
                }}
                className="w-full border-2 focus:ring-2 focus:ring-indigo-500 hover:border-indigo-400 transition-colors"
                placeholder="Enter your quiz topic"
              />
            </TabsContent>

            <TabsContent value="youtube" className="space-y-4 p-1">
              <Input
                value={ytLink}
                onChange={(e) => {
                  setYtLink(e.target.value);
                  setFile(null);
                  setTopic(null);
                }}
                className="w-full border-2 focus:ring-2 focus:ring-indigo-500 hover:border-indigo-400 transition-colors"
                placeholder="Paste YouTube video URL"
              />
            </TabsContent>

            <TabsContent value="doc" className="space-y-4 px-1">
              <div className="relative">
                <Input
                  type="file"
                  onChange={handleFileChange}
                  className="hidden"
                  id="file-upload"
                  accept=".pdf,.doc,.docx,.txt"
                />
                <Button
                  onClick={() => document.getElementById('file-upload').click()}
                  variant="outline"
                  className="w-full h-12 border-2 border-dashed hover:border-indigo-400 transition-colors">
                  <Upload className="w-4 h-4 mr-2" />
                  {files ? files.name : 'Upload Document'}
                </Button>
              </div>
            </TabsContent>
          </Tabs>

          <div className="flex items-center gap-2 p-1">
            <Input
              type="number"
              value={questionCount}
              onChange={(e) => setQuestionCount(e.target.value)}
              className="flex-1 border-2 focus:ring-2 focus:ring-indigo-500 hover:border-indigo-400 transition-colors"
              placeholder="Number of questions (optional, max 50)"
              min="1"
              max="50"
            />
            <Select value={difficulty} onValueChange={(value) => setDifficulty(value)}>
              <SelectTrigger className="flex-1 border-2 focus:ring-2 focus:ring-indigo-500 hover:border-indigo-400 transition-colors">
                <SelectValue placeholder="Select a difficulty" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectLabel>Difficulty</SelectLabel>
                  <SelectItem value="easy">Easy</SelectItem>
                  <SelectItem value="medium">Medium</SelectItem>
                  <SelectItem value="hard">Hard</SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>

          <Button
            onClick={createQnA}
            disabled={loading}
            className="w-full h-12 bg-gradient-to-r from-orange-600 to-purple-600 hover:from-orange-700 hover:to-purple-700 text-white font-semibold rounded-lg transition-all duration-700 transform hover:scale-[1.02] disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:scale-100">
            {loading ? (
              <div className="flex items-center justify-center spac e-x-2">
                <Loader2 className="w-5 h-4 animate-spin" />
                <span>Creating Quiz...</span>
              </div>
            ) : (
              <span className="flex items-center justify-center gap-2">
                Generate Quiz <span className="text-lg">🎯</span>
              </span>
            )}
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};

export default CreateQuiz;
