import React from 'react';
import { Pencil, Timer, Award } from 'lucide-react';
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from '../../ui/sheet';
import { Card, CardHeader, CardContent } from '../../ui/card';
import FlashCarousle from './FlashCarousle';
import { timeAgo } from '../../../services';

const QuizMetricItem = ({ icon: Icon, label, value }) => (
  <div className="flex items-center space-x-2">
    <Icon className="h-4 w-4 text-sidebar-accent-foreground" />
    <span className="text-sm text-sidebar-accent-foreground">
      {value} {label}
    </span>
  </div>
);

const QuizItem = ({ flashCard }) => {
  const { title, flashCards, createdAt } = flashCard;
  const estimatedTime = flashCards.length * 2;

  return (
    <Sheet>
      <SheetTrigger asChild>
        <Card className="group relative overflow-hidden cursor-pointer hover:shadow-md transition-all duration-300 border border-slate-200 bg-muted">
          <div className="absolute inset-0 bg-gradient-to-r from-indigo-500/10 to-purple-500/10 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />

          <CardHeader className="pb-4">
            <div className="flex justify-between items-start gap-4">
              <div className="flex-1 space-y-1 min-w-0">
                <h3 className="text-xl font-bold group-hover:text-indigo-600 transition-colors duration-200 line-clamp-2">
                  {title}
                </h3>
                <p className="text-sm text-muted-foreground">Created {timeAgo(createdAt)}</p>
              </div>
              <div className="flex-shrink-0">
                <Award className="h-6 w-6 text-amber-500 group-hover:scale-110 transition-transform duration-200" />
              </div>
            </div>
          </CardHeader>

          <CardContent className="space-y-4 min-h-20">
            <button className="rounded-xl px-1.5 py-0.5 border-2 border-brand bg-brand/20">
              <p className="text-xs text-brand">Flashcard</p>
            </button>
            <div className="flex justify-between items-center">
              <QuizMetricItem icon={Pencil} value={flashCards.length} label="Questions" />
              <QuizMetricItem icon={Timer} value={estimatedTime} label="min" />
            </div>
          </CardContent>
        </Card>
      </SheetTrigger>

      <SheetContent side="bottom" className="max-w-full h-5/6 p-0">
        <SheetHeader>
          <SheetTitle className="pt-4 px-4">{title}</SheetTitle>
        </SheetHeader>

        <div className="w-full h-full pt-12 px-2 md:pt-4">
          <FlashCarousle flashCards={flashCards} title={title} />
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default QuizItem;
