import axios from 'axios';
import axiosRetry from 'axios-retry';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const Axios = axios.create({
  baseURL: BASE_URL,
  timeout: 30000,
  withCredentials: true,
});

axiosRetry(Axios, {
  retries: 0,
  retryDelay: (retryCount) => {
    return retryCount * 1000;
  },
  retryCondition: (error) => {
    return axiosRetry.isNetworkOrIdempotentRequestError(error) || error?.response?.status >= 500;
  },
});

export default Axios;
