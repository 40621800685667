import React, { useState } from 'react';
import { Copy } from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';

function MarkdownView({ children }) {
  const [copy, setCopy] = useState('Copy');

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopy('Copied!');
      setTimeout(() => setCopy('Copy'), 1500);
    });
  };

  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      components={{
        p: ({ ...props }) => <p className="mb-3 sm:mb-2 text-sm md:text-base" {...props} />,
        h1: ({ ...props }) => <h1 className="my-2 font-bold text-lg md:text-xl" {...props} />,
        h2: ({ ...props }) => <h2 className="my-2 font-bold text-md md:text-lg" {...props} />,
        h3: ({ ...props }) => <h3 className="my-1 text-sm md:text-md font-medium" {...props} />,
        h4: ({ ...props }) => <h4 className="my-1 text-xs md:text-sm font-semibold" {...props} />,

        code: ({ inline, className, children, ...props }) => {
          const match = /language-(\w+)/.exec(className || '');
          const isCodeBlock = !inline && match;

          if (isCodeBlock) {
            const codeContent = String(children).replace(/\n$/, '');

            return (
              <div className="relative my-3 sm:my-2 rounded-lg overflow-x-auto group">
                <SyntaxHighlighter
                  style={vscDarkPlus}
                  language={match[1]}
                  PreTag="div"
                  className="rounded-lg text-xs sm:text-sm"
                  {...props}>
                  {codeContent}
                </SyntaxHighlighter>
                <button
                  onClick={() => handleCopy(codeContent)}
                  className="absolute top-3 right-2 bg-muted/40 p-1 rounded text-xs opacity-0 group-hover:opacity-100 transition-all duration-500 flex items-center gap-1">
                  <Copy className="w-3 h-3" />
                  {copy}
                </button>
              </div>
            );
          }

          return (
            <code
              className="bg-muted-foreground text-background font-semibold px-2 py-0.5 rounded text-xs sm:text-sm"
              {...props}>
              {children}
            </code>
          );
        },

        ul: ({ ...props }) => <ul className="pl-4 mb-5 sm:mb-3 text-sm" {...props} />,
        ol: ({ ...props }) => <ol className="pl-4 mb-5 sm:mb-3 text-sm" {...props} />,
        li: ({ ...props }) => <li className="mb-1 text-sm" {...props} />,

        a: ({ ...props }) => (
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline text-sm hover:opacity-75"
            {...props}
          />
        ),
        table: ({ ...props }) => (
          <table className="min-w-full rounded-lg bg-muted/40 text-xs sm:text-sm my-4" {...props} />
        ),
        thead: ({ ...props }) => (
          <thead className="bg-brand text-xs sm:text-sm">{props.children}</thead>
        ),
        tbody: ({ ...props }) => <tbody>{props.children}</tbody>,
        tr: ({ ...props }) => <tr className="border-t border-gray-300">{props.children}</tr>,
        th: ({ ...props }) => (
          <th className="px-3 py-1 text-secondary text-left font-semibold sm:px-2 sm:py-1">
            {props.children}
          </th>
        ),
        td: ({ ...props }) => (
          <td className="px-3 py-1 sm:px-2 sm:py-2 text-xs sm:text-sm">{props.children}</td>
        ),
      }}>
      {children}
    </ReactMarkdown>
  );
}

export default MarkdownView;
