import React, { useState, useEffect, useRef } from 'react';
import io from 'socket.io-client';
import { axios } from '../../../services';
import { Send } from 'lucide-react';
import { ScrollArea } from '../../ui/scroll-area';
import { Textarea } from '../../ui/textarea';
import { Button } from '../../ui/button';
import { useUser } from '@clerk/clerk-react';
import { Avatar, AvatarFallback, AvatarImage } from '../../ui/avatar';
import MarkdownView from '../..//MarkDownView';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const socket = io(BASE_URL);

const ChatRoom = ({ communityId }) => {
  const { user } = useUser();
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const scrollRef = useRef(null);

  const getMsgs = async () => {
    try {
      const { data } = await axios.get(`/chat/${communityId}`);
      const newMessages = data.data;

      const storedMessages = JSON.parse(localStorage.getItem(communityId)) || [];
      setMessages(storedMessages);
      const allMessages = [...storedMessages];

      newMessages.forEach((newMsg) => {
        if (!allMessages.some((msg) => msg._id === newMsg._id)) {
          allMessages.push(newMsg);
        }
      });

      setMessages(allMessages);
      localStorage.setItem(communityId, JSON.stringify(allMessages));
    } catch (error) {
      console.log('Error fetching messages', error);
    }
  };

  useEffect(() => {
    getMsgs();
  }, []); // Only run on component mount

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  useEffect(() => {
    if (communityId) {
      socket.emit('joinRoom', communityId);
    }

    // Listen for incoming messages
    socket.on('receiveMessage', (data) => {
      console.log(data);

      // Check if the incoming message is already in the stored messages to avoid duplication
      setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages];

        if (!updatedMessages.some((msg) => msg._id === data._id)) {
          updatedMessages.push(data);
          // Update localStorage with the new messages
          localStorage.setItem(communityId, JSON.stringify(updatedMessages));
        }

        return updatedMessages;
      });
    });

    // Cleanup on unmount
    return () => {
      socket.off('receiveMessage');
    };
  }, [communityId]);

  const sendMessage = async () => {
    try {
      if (message.trim()) {
        const res = await axios.post(`/chat/${communityId}`, { sender: user.id, message });
        setMessage('');
      }
    } catch (error) {
      console.log('Error sending message', error);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  const formatTimestamp = (timestamp) => {
    return new Date(timestamp).toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  return (
    <div className="w-full h-full flex flex-col mx-auto">
      <ScrollArea className="flex-grow h-full p-2">
        <div className="space-y-4">
          {messages?.map((msg, index) => (
            <div
              key={index}
              ref={index === messages.length - 1 ? scrollRef : null} // Attach ref to the last message
              className={`flex gap-3 ${
                msg?.sender?._id === user?.id || msg?.sender === user?.id
                  ? 'flex-row-reverse'
                  : 'flex-row'
              }`}>
              <div className="flex-shrink-0">
                <Avatar>
                  <AvatarImage />
                  <AvatarFallback>CN</AvatarFallback>
                </Avatar>
              </div>

              <div
                className={`max-w-[80%] rounded-lg p-3 ${
                  msg?.sender?._id === user?.id || msg?.sender === user?.id
                    ? 'bg-blue-500 text-white'
                    : 'bg-gray-100 text-gray-800'
                }`}>
                <div className="font-medium text-sm mb-1">
                  {msg?.sender?.username}
                  <span className="ml-2 text-xs opacity-70">{formatTimestamp(msg?.timestamp)}</span>
                </div>
                <MarkdownView>{msg?.message}</MarkdownView>
              </div>
            </div>
          ))}
        </div>
      </ScrollArea>

      <div className="p-4 border-t flex gap-2 flex-shrink-0 items-center">
        <Textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder="Type a message..."
          className="flex-1 overflow-y-auto"
        />
        <Button onClick={sendMessage} disabled={!message.trim()} className="px-4">
          <Send className="w-4 h-4" />
        </Button>
      </div>
    </div>
  );
};

export default ChatRoom;
