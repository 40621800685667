import React, { useState } from 'react';
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from '../../ui/sheet';
import { Settings } from 'lucide-react';
import SideBar from './Sidebar';
import Main from './Main';

function SettingsComp({ course }) {
  const [toggle, setToggle] = useState('train_course');

  return (
    <>
      <Sheet>
        <SheetTrigger asChild>
          <Settings className="cursor-pointer mx-2 transition-transform duration-300 hover:rotate-45" />
        </SheetTrigger>

        <SheetContent
          side="right"
          className="min-w-full md:min-w-[600px] lg:min-w-[900px] bg-background/90 ">
          <SheetHeader>
            <SheetTitle>
              Settings
              <p className="text-sm font-thin text-brand">manage your course</p>
            </SheetTitle>
          </SheetHeader>
          <div className="flex flex-start py-6 h-full">
            <SideBar toggle={toggle} setToggle={setToggle} />
            <Main course={course} toggle={toggle} />
          </div>
        </SheetContent>
      </Sheet>
    </>
  );
}

export default SettingsComp;
