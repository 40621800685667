import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlusCircle, Loader2 } from 'lucide-react';
import { Header, InputFields, Upload, Files } from './CreateCourse';
import { Card, CardHeader, CardFooter, CardContent } from '../ui/card';
import { Button } from '../ui/button';
import { axios } from '../../services';
import { useCourse } from '../../context/useCourseContext';
import { useTranslation } from 'react-i18next';

const CourseCreation = () => {
  const { setCourse, user } = useCourse();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [courseType, setCourseType] = useState('ai');
  const [courseDetails, setCourseDetails] = useState({
    title: '',
    level: '',
    depth: '',
    language: '',
  });
  const [files, setFiles] = useState([]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCourseDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileUpload = (e) => {
    const uploadedFiles = Array.from(e.target.files);
    setFiles((prevFiles) => [...prevFiles, ...uploadedFiles]);
  };
  useEffect(() => {
    setFiles([]);
  }, [courseType]);

  const handleCreateCourse = async () => {
    if (!courseDetails.title.trim() || !courseDetails.level.trim() || !courseDetails.depth.trim()) {
      setError('All fields are required.');
      return;
    }
    setSubmitting(true);
    setError(null);

    const formData = new FormData();
    formData.append('userId', user.id);
    formData.append('title', courseDetails.title);
    formData.append('level', courseDetails.level);
    formData.append('depth', courseDetails.depth);
    formData.append('language', courseDetails.language);
    formData.append('course_type', courseType);

    files.forEach((file) => {
      formData.append('files', file);
    });

    try {
      const response = await axios.post('/course/new', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        timeout: 900000,
      });
      setCourse(response.data.data);
      setSubmitting(false);
      setFiles([]);
      setCourseDetails({
        title: '',
        level: '',
        depth: '',
        language: '',
      });
      navigate(`/courses/${response.data.data?.title}?id=${response.data.data?._id}`);
    } catch (error) {
      console.error('Error submitting the form:', error);
      setError('Failed to create the course. Please try again.');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <div>
        <div className="h-full flex justify-center items-center">
          <Card className="w-full max-w-5xl bg-muted/40">
            <CardContent className="py-8 px-2">
              <div className="flex flex-col md:flex-row items-center gap-8">
                {/* Left Section */}
                <div className="flex flex-col justify-between">
                  <CardHeader>
                    <Header />
                  </CardHeader>
                  <InputFields
                    courseDetails={courseDetails}
                    handleInputChange={handleInputChange}
                    submitting={submitting}
                  />
                  {error && <div className="text-red-500 text-sm text-center mt-2">{error}</div>}
                </div>

                {/* Divider */}
                <div className="hidden md:block w-px bg-border/50 self-stretch mx-2" />

                {/* Right Section */}
                <div className="flex-1 px-4">
                  <div className="space-y-6">
                    <div className="space-y-2">
                      <h3 className="text-lg font-semibold">Course Type</h3>
                      <div className="flex justify-between gap-2">
                        {['ai', 'syllabus', 'doc'].map((type) => (
                          <Button
                            key={type}
                            className={`flex-1 ${
                              courseType === type
                                ? 'bg-brand hover:bg-brand-hover'
                                : 'bg-gray-300 hover:bg-gray-400'
                            }`}
                            onClick={() => setCourseType(type)}>
                            {type.toUpperCase()}
                          </Button>
                        ))}
                      </div>
                    </div>

                    <Upload
                      handleFileUpload={handleFileUpload}
                      submitting={submitting}
                      courseType={courseType}
                    />
                    <Files files={files} setFiles={setFiles} submitting={submitting} />
                  </div>
                </div>
              </div>
            </CardContent>
            <CardFooter className="flex justify-center px-6 pb-6">
              <Button
                className="bg-brand hover:bg-brand-hover w-full max-w-md"
                disabled={submitting}
                onClick={handleCreateCourse}>
                {submitting ? (
                  <Loader2 className="h-5 w-5 animate-spin" />
                ) : (
                  <PlusCircle className="h-5 w-5" />
                )}
                <span className="ml-2">
                  {submitting ? t('homePage.button.creating') : t('homePage.button.createCourse')}
                </span>
              </Button>
            </CardFooter>
          </Card>
        </div>
      </div>
    </>
  );
};

export default CourseCreation;
