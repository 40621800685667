import React from 'react';
import { Users, Trash, MessagesSquare, Sparkles, Book } from 'lucide-react';
import { cn } from '../../../lib/utils';

const Sidebar = ({ toggle, setToggle }) => {
  const menuItems = [
    { value: 'train_course', icon: Sparkles, label: 'Course Training' },
    { value: 'course_settings', icon: Book, label: 'Course Settings' },
    { value: 'chat_settings', icon: MessagesSquare, label: 'Chat Settings' },
    { value: 'members', icon: Users, label: 'Members' },
    { value: 'delete_course', icon: Trash, label: 'Delete Course' },
  ];

  return (
    <div className="h-full flex flex-col w-1/2 mb-2">
      <div className="flex-1 p-3 space-y-1 bg-muted/40 rounded-xl w-full mb-2">
        <div className="flex flex-col w-full gap-1 mb-2">
          {menuItems.map(({ value, icon: Icon, label, disabled }) => (
            <button
              key={value}
              disabled={disabled}
              onClick={() => setToggle(value)}
              className={cn(
                'flex items-center gap-2 px-3 py-2 text-sm rounded-md',
                'transition-all duration-200',
                'hover:bg-brand-hover',
                'disabled:opacity-50 disabled:cursor-not-allowed',
                toggle === value ? 'bg-brand text-primary-foreground' : 'w-full text-left'
              )}>
              <Icon className="h-4 w-4" />
              <span>{label}</span>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
