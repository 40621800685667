import React from 'react';
import { UploadCloud } from 'lucide-react';
import { Input } from '../../ui/input';
import { Label } from '../../ui/label';
import { useTranslation } from 'react-i18next';

function Upload({ handleFileUpload, submitting, courseType }) {
  const { t } = useTranslation();

  // Determine the upload constraints based on courseType
  const isDisabled = courseType === 'ai';
  const isMultiple = courseType === 'doc';
  const acceptFileTypes = '.pdf';
  const maxFiles = isMultiple ? 5 : 1;

  const handleInputChange = (event) => {
    const files = event.target.files;

    if (files.length > maxFiles) {
      alert(t('homePage.upload.messages.tooManyFiles', { max: maxFiles }));
      event.target.value = '';
      return;
    }

    handleFileUpload(event);
  };

  return (
    <div className="group px-2">
      <div
        className={`border-2 border-dashed rounded-xl p-8 text-center 
        hover:border-brand group-hover:bg-muted/50 group-hover:shadow-sm group-hover:transition-colors 
        ${isDisabled ? 'opacity-50 pointer-events-none' : ''}`}>
        <Input
          disabled={submitting || isDisabled}
          type="file"
          id="file-upload"
          className="hidden"
          multiple={isMultiple}
          accept={acceptFileTypes}
          onChange={handleInputChange}
        />
        <Label htmlFor="file-upload" className="cursor-pointer">
          <div className="flex flex-col items-center gap-4">
            <UploadCloud className="h-10 w-10 text-brand/40 group-hover:text-brand" />
            <span className="font-medium text-muted-foreground pb-2">
              {t('homePage.upload.labels.uploadFiles')}
            </span>
          </div>
        </Label>
      </div>
    </div>
  );
}

export default Upload;
