import React from 'react';
import { ScrollArea } from '../../ui/scroll-area';
import MarkdownView from '../../MarkDownView';
import { Avatar, AvatarFallback, AvatarImage } from '../../ui/avatar';
import { useUser } from '@clerk/clerk-react';

function MessageArea({ messages, isLoading, bottomRef }) {
  const { user } = useUser();
  return (
    <>
      <ScrollArea className="h-full flex-1 px-4">
        <div className={`py-4 space-y-4`}>
          {messages.map((message) => (
            <div
              className={`flex items-start gap-1 ${
                message.sender === 'user' ? 'flex-row-reverse' : 'flex-row'
              }`}>
              <Avatar className="flex-shrink-0 size-8">
                <AvatarImage
                  src={
                    message.sender === 'user'
                      ? user.imageUrl
                      : require('../../../assets/images/teal_icon.png')
                  }
                />

                <AvatarFallback>AI</AvatarFallback>
              </Avatar>

              <div
                className={`max-w-[80%] px-5 mt-4 pt-2 ${
                  message.sender === 'user'
                    ? 'bg-brand text-white rounded-l-3xl rounded-br-3xl'
                    : 'bg-brand-hover/40 rounded-r-3xl rounded-bl-3xl'
                }`}>
                <MarkdownView>{message.content}</MarkdownView>
              </div>
            </div>
          ))}

          {isLoading && (
            <div className="flex justify-start gap-1">
              <Avatar className="flex-shrink-0 size-8">
                <AvatarImage src={require('../../../assets/images/teal_icon.png')} />
                <AvatarFallback>AI</AvatarFallback>
              </Avatar>
              <div className="mt-4 px-4 py-3 rounded-r-3xl rounded-bl-3xl bg-brand-hover/40">
                <div className="flex items-center space-x-2 py-1">
                  <div className="w-2 h-2 bg-pastel-white rounded-full animate-bounce" />
                  <div className="w-2 h-2  bg-pastel-white rounded-full animate-bounce delay-100" />
                  <div className="w-2 h-2  bg-pastel-white rounded-full animate-bounce delay-200" />
                </div>
              </div>
            </div>
          )}

          {/* Auto-scroll reference */}
          <div ref={bottomRef} />
        </div>
      </ScrollArea>
    </>
  );
}

export default MessageArea;
