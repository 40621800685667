import React, { useState, useEffect } from 'react';
import { axios } from '../../../services';
import { useCourse } from '../../../context/useCourseContext';
import { ScrollArea } from '../../ui/scroll-area';
import QuizItem from './QuizItem';
import CreateQuiz from './CreateQuiz';

function Quiz({ course }) {
  const { isAdmin } = useCourse();
  const [quizes, setQuiz] = useState([]);

  const getQuiz = async () => {
    try {
      const { data } = await axios.get(`/practice/quiz/${course._id}`);
      setQuiz(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getQuiz();
  }, []);

  return (
    <ScrollArea className="h-full">
      <div className="space-y-8">
        {/* <div>{isAdmin && <CreateQuiz course={course} setQuiz={setQuiz} />}</div> */}
        <div>{<CreateQuiz course={course} setQuiz={setQuiz} />}</div>
        <div className="grid grid-cols sm:grid-cols-2 md:grid-cols-3 px-1 gap-2 pb-10">
          {quizes?.map((quiz) => (
            <div className="p-2" key={quiz._id}>
              <QuizItem quiz={quiz} />
            </div>
          ))}
        </div>
      </div>
    </ScrollArea>
  );
}

export default Quiz;
