import { Button } from '../ui/button';
import { Sparkles } from 'lucide-react';

export default function Component({ children }) {
  return (
    <div className="flex items-center justify-center rounded-lg border border-dashed shadow-sm p-4 sm:p-20">
      <div className="flex flex-col items-center gap-1 text-center h-full">
        {children ? (
          <h3 className="text-2xl font-bold tracking-tight">{children}</h3>
        ) : (
          <>
            <h3 className="text-2xl font-bold tracking-tight">You have no courses</h3>
          </>
        )}
        <p className="text-sm text-muted-foreground">
          You can start reading as soon as you generate a course.
        </p>
        <Button className="mt-4">
          <h1 className="flex gap-1">
            Generate <Sparkles size={18} />
          </h1>
        </Button>
      </div>
    </div>
  );
}
