import { useContext } from 'react';
import { SidebarContext } from '../SideBar';
import { Link } from 'react-router-dom';

export default function SidebarItem({ icon, text, to, alert }) {
  const { expanded, pathname } = useContext(SidebarContext);
  const isActive = pathname.startsWith(to);

  return (
    <Link
      to={to}
      className={`relative flex items-center py-1.5 px-2 my-0.5 font-medium text-sm rounded-md cursor-pointer transition-all duration-300 group hover:translate-y-0.5 ${
        isActive ? 'bg-brand/40' : 'text-primary/70 hover:bg-muted/70'
      } ${!expanded && 'justify-center'}`}>
      <div
        className={`flex items-center justify-center min-w-6 min-h-6 transition-all duration-300 ${
          expanded ? 'scale-100' : 'scale-90'
        }`}>
        {icon}
      </div>
      <span
        className={`overflow-hidden transition-all duration-300 ${
          expanded ? 'ml-2 opacity-100' : 'opacity-0 hidden'
        }`}>
        {text}
      </span>
      {alert && (
        <div
          className={`absolute right-1 w-2 h-2 rounded-full bg-red-500 transition-all`}
          style={{
            top: expanded ? 'auto' : '1.5rem',
            transition: 'top 0.3s ease',
          }}
        />
      )}
      {!expanded && (
        <div className="absolute left-full rounded-md px-2 py-1 ml-6 text-xs invisible opacity-0 -translate-x-3 transition-all duration-300 group-hover:visible group-hover:opacity-100 group-hover:translate-x-0 bg-muted">
          {text}
        </div>
      )}
    </Link>
  );
}
