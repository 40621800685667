import React from 'react';
import { Info, HelpCircle, MessageCircleQuestion } from 'lucide-react';

function Tips({ showTips }) {
  return (
    <>
      {showTips && (
        <>
          <div className="flex items-center gap-2 mx-6 px-2 mt-2 border-dashed border rounded-lg py-1 bg-muted/40">
            <HelpCircle className="h-4 w-4 text-muted-foreground" />
            <p className="text-sm text-muted-foreground">*Currently in Beta Pro Max</p>
          </div>
          <div className="grid md:grid-cols-2 gap-4 p-2 mx-6 mt-2 bg-gradient-to-br from-brand/10 via-brand/5 to-brand/10 rounded-xl shadow-sm">
            <div className="p-4 bg-gradient-to-tr from-brand/5 to-brand/10 rounded-lg border border-brand/30 hover:border-brand/50 transition-all">
              <div className="flex items-center mb-3 space-x-2">
                <MessageCircleQuestion className="w-5 h-5 text-brand/70" />
                <h3 className="text-base font-medium text-brand-foreground">Accurate Results</h3>
              </div>
              <ul className="space-y-1 text-sm text-muted-foreground">
                {['Include topic name', 'Be specific', 'Provide context', 'Rephrase if needed'].map(
                  (item, i) => (
                    <li key={i} className="flex items-center space-x-2">
                      <span className="w-1 h-1 rounded-full bg-brand/50" />
                      <span>{item}</span>
                    </li>
                  )
                )}
              </ul>
            </div>

            <div className="p-4 bg-gradient-to-tr from-brand/5 to-brand/10 rounded-lg border border-brand/30 hover:border-brand/50 transition-all">
              <div className="flex items-center mb-3 space-x-2">
                <Info className="w-5 h-5 text-brand/70" />
                <h3 className="text-base font-medium text-brand-foreground">What to Ask</h3>
              </div>
              <ul className="space-y-1 text-sm text-muted-foreground">
                {[
                  'Summarize key points',
                  'Explain concepts',
                  'Ask specific info',
                  'Ask for difference, this vs that',
                ].map((item, i) => (
                  <li key={i} className="flex items-center space-x-2">
                    <span className="w-1 h-1 rounded-full bg-brand/50" />
                    <span>{item}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Tips;
