import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import CourseCard from '../components/CourseCard';
import { AdminCourses } from '../components/home';
import { Create } from '../components/course';
import { ScrollArea } from '../components/ui/scroll-area';
import { useCourse } from '../context/useCourseContext';

function Courses() {
  const { user, courses } = useCourse();
  const location = useLocation();
  const initialData = location.state?.data || [];
  const [data, setData] = useState(initialData);

  useEffect(() => {
    if (initialData?.length === 0 || initialData === 'undefined') {
      setData(courses);
    } // eslint-disable-next-line
  }, [initialData?.length, user.id]);

  return (
    <ScrollArea className="flex-1">
      <div className="container my-4">
        <Create>Learn a new Topic</Create>
        <div className="my-4">
          <h2 className="font-bold text-lg">Your Courses</h2>
        </div>
        {data?.length > 0 ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {data.map((course) => (
              <div key={course?.course?._id || course?._id}>
                <CourseCard course={course?.course || course} viewed={course?.viewedAt} />
              </div>
            ))}
          </div>
        ) : (
          <p className="text-center">No courses available.</p>
        )}
        <AdminCourses />
      </div>
    </ScrollArea>
  );
}

export default Courses;
