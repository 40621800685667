import React from 'react';
import { Button } from '../../ui/button';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../ui/select';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '../../ui/card';
import { Badge } from '../../ui/badge';

const MemberSettings = () => {
  const pendingRequests = 3; // Example number

  return (
    <Card className="w-full">
      <CardHeader>
        <CardTitle>Member Settings</CardTitle>
        <CardDescription>Configure member access and permissions</CardDescription>
      </CardHeader>
      <CardContent className="space-y-6">
        <div className="space-y-2">
          <label className="font-medium">Join Settings</label>
          <div className="p-1">
            <Select>
              <SelectTrigger>
                <SelectValue placeholder="Select join setting" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="open">Anyone can join</SelectItem>
                <SelectItem value="approval">Require approval</SelectItem>
                <SelectItem value="closed">Closed to new members</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>
        <div className="space-y-2">
          <div className="flex items-center justify-between">
            <div className="font-medium">Pending Requests</div>
            <Badge variant="secondary">{pendingRequests}</Badge>
          </div>
          <Button variant="outline" className="w-full">
            View Requests
          </Button>
        </div>
        <Button variant="outline" className="w-full">
          Manage Members
        </Button>
      </CardContent>
    </Card>
  );
};

export default MemberSettings;
