import React from 'react';
import { motion } from 'framer-motion';
import { Book, Briefcase, Code, Video, Sailboat, Calendar } from 'lucide-react';
import { useTranslation } from 'react-i18next';

const features = [
  {
    key: 'aiCourseGeneration',
    icon: Video,
  },
  {
    key: 'documentChat',
    icon: Book,
  },
  {
    key: 'courseCommunity',
    icon: Code,
  },
  {
    key: 'topicsMaterialAndVideos',
    icon: Sailboat,
  },
  {
    key: 'quizzesAndFlashcards',
    icon: Briefcase,
  },
  {
    key: 'readingResources',
    icon: Calendar,
  },
];

const ProductFeatures = () => {
  const { t } = useTranslation();

  const backgroundVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: 'easeOut',
      },
    },
  };

  const cardVariants = {
    hidden: { opacity: 0, scale: 0.95 },
    visible: (index) => ({
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.5,
        delay: index * 0.1,
        ease: 'easeOut',
      },
    }),
    hover: {
      scale: 1.03,
      transition: {
        duration: 0.3,
        ease: 'easeInOut',
      },
    },
  };

  const pastelColors = [
    'bg-pastel-lavendar/50',
    'bg-pastel-neon/50',
    'bg-pastel-blue/50',
    'bg-pastel-white/50',
    'bg-pastel-pink/50',
    'bg-pastel-tan/50',
  ];

  return (
    <section id="what-we-do" className=" bg-pastel-blue/20 rounded-2xl mt-10">
      <motion.div
        variants={backgroundVariants}
        initial="hidden"
        animate="visible"
        className="container mx-auto relative h-full px-4 my-10">
        {/* Header */}
        <div className="text-center mb-16">
          <motion.h1
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="text-5xl md:text-6xl font-extrabold bg-clip-text text-transparent 
            bg-gradient-to-r from-green-400 via-violet-500 pt-2 to-green-600 mb-4">
            {t('productFeatures.header.title')}
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="text-xl md:text-2xl max-w-3xl mx-auto text-gray-700">
            {t('productFeatures.header.description')}
          </motion.p>
        </div>

        {/* Features Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 p-4 cursor-cell">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              custom={index}
              variants={cardVariants}
              initial="hidden"
              whileInView="visible"
              whileHover="hover"
              viewport={{ once: true, amount: 0.2 }}
              className={`border border-green-100/50 rounded-3xl p-6 
                        transition-all duration-300 
                        hover:border-violet-500/50
                        hover:shadow-[0_15px_50px_-15px_rgba(124,58,237,0.3)] ${
                          pastelColors[index % pastelColors.length]
                        }`}>
              <div className="flex items-center mb-4">
                <div
                  className="flex items-center justify-center bg-gradient-to-r from-green-500/20 to-violet-500/20 
              border border-green-500/30
              rounded-full w-14 h-12 mr-4 
              shadow-[0_8px_20px_-10px_rgba(124,58,237,0.3)]">
                  <feature.icon className="w-6 h-6 text-violet-600" />
                </div>
                <h2 className="text-2xl font-bold text-pastel-green">
                  {t(`productFeatures.features.${feature.key}.title`)}
                </h2>
              </div>
              <p className="text-gray-900 leading-relaxed">
                {t(`productFeatures.features.${feature.key}.description`)}
              </p>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </section>
  );
};

export default ProductFeatures;
