import React from 'react';
import { FileIcon, X, Eye } from 'lucide-react';
import { Button } from '../../ui/button';
import { ScrollArea } from '../../ui/scroll-area';

function Files({ files, setFiles, submitting }) {
  const handleViewFile = (file) => {
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL, '_blank');
  };

  return (
    <ScrollArea className={`h-[180px] mt-2 px-3`}>
      <div className="space-y-2">
        {files.map((file, index) => (
          <div key={index} className="flex items-center justify-between p-2 bg-muted rounded-lg">
            <div className="flex items-center gap-3">
              <FileIcon className="h-5 w-5 text-foreground" />
              <div>
                <p className="text-sm font-medium text-foreground">{file.name}</p>
                <p className="text-xs text-foreground/50">
                  {(file.size / 1024 / 1024).toFixed(2)} MB
                </p>
              </div>
            </div>
            <div className="flex gap-2">
              <Button
                variant="outline"
                size="icon"
                className="rounded-full"
                onClick={() => handleViewFile(file)}
                disabled={submitting}>
                <Eye className="h-4 w-4" />
              </Button>
              <Button
                disabled={submitting}
                variant="outline"
                size="icon"
                className="rounded-full"
                onClick={() => setFiles(files.filter((_, i) => i !== index))}>
                <X className="h-4 w-4" />
              </Button>
            </div>
          </div>
        ))}
      </div>
    </ScrollArea>
  );
}

export default Files;
