import React from 'react';
import { Clipboard } from 'lucide-react';
import { Link } from 'react-router-dom';

const GraphCard = () => {
  return (
    <div className="group w-full mb-8 bg-muted/40 hover:bg-muted/60 backdrop-blur-lg border border-muted/20 hover:border-muted/40 shadow-lg hover:shadow-xl rounded-lg p-6 transition-all duration-300">
      <div className="flex items-start gap-4">
        <div className="p-2 flex items-center bg-brand/10 group-hover:bg-brand/20 rounded-full text-brand transition-colors duration-300">
          <div className="w-full">
            <Clipboard className="h-6 w-6" aria-hidden="true" />
          </div>
        </div>
        <div className="flex-1">
          <h3 className="text-xl font-semibold text-brand">Complete DSA Sheet</h3>
          <p className="text-muted-foreground mt-2">
            Access a comprehensive collection of DSA problems to solidify your understanding and
            prepare for any challenge.
          </p>
          <Link
            to="/dsa/sheet"
            className="mt-4 inline-flex items-center text-sm font-medium text-brand hover:underline focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand rounded w-full">
            <span>Explore now</span>
            <span className="ml-1 transform group-hover:translate-x-1 transition-transform duration-300">
              →
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default GraphCard;
