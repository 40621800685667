import React from 'react';
import { useTranslation } from 'react-i18next';
import { Book, Target, Users } from 'lucide-react';
import { Card } from '../ui/card';
import { useUser } from '@clerk/clerk-react';

function Features() {
  const { user } = useUser();
  const { t } = useTranslation();

  const featuresList = [
    {
      icon: Book,
      title: t('featuresPage.features.aiCourse.title'),
      description: t('featuresPage.features.aiCourse.description'),
    },
    {
      icon: Target,
      title: t('featuresPage.features.docChat.title'),
      description: t('featuresPage.features.docChat.description'),
    },
    {
      icon: Users,
      title: t('featuresPage.features.community.title'),
      description: t('featuresPage.features.community.description'),
    },
  ];

  return (
    <div>
      <div className="h-full max-w-5xl mx-auto ">
        <div className="py-4 px-4 sm:px-6 lg:px-6 h-full flex flex-col lg:flex-row items-start gap-4">
          {/* Intro Section */}
          <div className="py-4 text-center lg:text-left lg:max-w-sm">
            <h1
              className="text-4xl sm:text-3xl font-extrabold text-brand mb-2 "
              style={{
                fontFamily: 'Finger Paint, serif',
              }}>
              {t('featuresPage.intro.greeting', { userFirstName: user?.firstName })}
            </h1>
            <h2 className="text-xl sm:text-2xl font-bold text-foreground/90 mb-2">
              {t('featuresPage.intro.heading')}
            </h2>
            <p className="text-sm text-muted-foreground leading-relaxed">
              {t('featuresPage.intro.description')}
            </p>
          </div>

          {/* Features Section */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 flex-1 pt-2">
            {featuresList.map((feature, index) => (
              <Card
                key={index}
                className="p-4 bg-muted/40 shadow hover:shadow-md transition-all duration-200 hover:-translate-y-1">
                <div className="flex items-start gap-3">
                  <feature.icon className="w-8 h-8 text-brand shrink-0" />
                  <div>
                    <h3 className="text-base font-semibold text-foreground mb-1">
                      {feature.title}
                    </h3>
                    <p className="text-sm text-muted-foreground">{feature.description}</p>
                  </div>
                </div>
              </Card>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Features;
