import React from 'react';
import { CircleCheckBig, Star } from 'lucide-react';

const plans = [
  {
    name: 'Free',
    price: 'Free',
    amount: 0,
    duration: 1,
    description:
      'Get a taste of our premium content with a one-month access to select courses and resources.',
    features: ['One AI Course', 'Semester Course'],
    bestFor: 'Best for Beginners',
    bgColor: 'bg-blue-50',
    textColor: 'text-blue-800',
  },
  {
    name: 'Trial Adventurer',
    originalPrice: '₹159',
    price: '₹199',
    amount: 129,
    duration: 1,
    description: 'Explore our platform with a low-cost entry and access to premium features.',
    features: ['All Course features', 'One AI Course'],
    bestFor: 'Best for Exploration',
    bgColor: 'bg-green-50',
    textColor: 'text-green-800',
  },
  {
    name: 'Semester Seeker',
    originalPrice: '₹360',
    price: '₹169',
    amount: 49,
    duration: 6,
    description:
      'Dive deeper with a six-month journey through our extensive course library and project resources.',
    features: ['Three AI Courses', 'Placement Blogs', 'Project Baskets', 'Comprehensive DSA'],
    bestFor: 'Most Popular',
    bgColor: 'bg-purple-50',
    textColor: 'text-purple-800',
    recommended: true,
  },
  {
    name: 'Annual Achiever',
    originalPrice: '₹720',
    price: '₹149',
    amount: 99,
    duration: 12,
    description:
      'Unlock unlimited premium content for a full year, including AI courses and exclusive project resources.',
    features: ['Unlimited AI Courses', 'Placement Blogs', 'Project Baskets', 'Career Guidance'],
    bestFor: 'Best Value',
    bgColor: 'bg-orange-50',
    textColor: 'text-orange-800',
  },
];

const SubscriptionPlans = () => {
  const calculateDiscount = (originalPrice, discountedPrice) => {
    const original = parseFloat(originalPrice.replace('₹', ''));
    const discounted = parseFloat(discountedPrice.replace('₹', ''));
    const discount = ((original - discounted) / original) * 100;
    return discount.toFixed(0);
  };

  const PlanCard = ({ plan }) => {
    const discountPercentage = plan.originalPrice
      ? calculateDiscount(plan.originalPrice, plan.price)
      : null;

    return (
      <div
        className={`
        relative rounded-2xl overflow-hidden shadow-lg transition-all duration-300 
        hover:shadow-2xl transform hover:-translate-y-2 
        ${plan.bgColor} border border-opacity-50 my-6
      `}>
        {plan.recommended && (
          <div
            className="absolute top-0 left-0 right-0 bg-purple-500 text-white 
            text-center py-1 text-sm flex items-center justify-center">
            <Star className="mr-2 w-4 h-4" />
            Most Popular
          </div>
        )}

        <div className="p-6 py-8 flex flex-col h-full">
          <div className="mb-4">
            <h2 className={`text-xl font-bold mb-2 ${plan.textColor}`}>{plan.name}</h2>
            <p className="text-gray-600 text-sm mb-4 h-16">{plan.description}</p>

            {plan.amount === 0 ? (
              <div className="text-2xl font-bold text-gray-700">Free</div>
            ) : (
              <div className="flex items-center gap-3">
                <span className="text-2xl font-bold">{plan.price}</span>
                {plan.originalPrice && (
                  <>
                    <span className="line-through text-gray-400">{plan.originalPrice}</span>
                    <span className="text-green-600 font-semibold">{discountPercentage}% OFF</span>
                  </>
                )}
              </div>
            )}
            <div className="text-sm text-gray-500 mt-1">
              {plan.duration} Month{plan.duration > 1 ? 's' : ''} Access
            </div>
          </div>

          <div className="flex-grow">
            <h3 className="text-md font-semibold mb-3 text-gray-700">What's Included:</h3>
            <ul className="space-y-2">
              {plan.features.map((feature, idx) => (
                <li key={idx} className="flex items-center">
                  <CircleCheckBig className="text-green-500 mr-2 w-5 h-5" />
                  <span className="text-gray-700">{feature}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  };

  return (
    <section id="pricing" className="bg-pastel-green py-16 px-4 my-8 rounded-2xl">
      <div className="container mx-auto">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold mb-4 text-pastel-cream">Ace Your Semester & Beyond</h1>
          <p className="text-gray-100 max-w-3xl mx-auto">
            Unlock comprehensive AI-generated content, DSA sheets, project baskets, and placement
            blogs. Get exclusive access to semester courses with handwritten notes, PYQs, detailed
            topic reading materials, and curated YouTube videos.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {plans.map((plan, index) => (
            <PlanCard key={index} plan={plan} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default SubscriptionPlans;
