import { useState, useEffect, useRef } from 'react';
import { Send, Paperclip, Loader2, Youtube } from 'lucide-react';
import { axios } from '../../../services';
import Tips from './Tips';
import MessageArea from './MessageArea';

const DocumentChat = ({ courseId, userId, title }) => {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [ytLink, setYtLink] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showTips, setShowTips] = useState(true);
  const [context, setContext] = useState('null');
  const [chatSummary, setChatSummary] = useState('');
  const [inputType, setInputType] = useState('chat');

  const bottomRef = useRef(null);

  function handleFileUpload(event) {
    const file = event.target.files[0];
    if (file && ['application/pdf', 'text/plain', 'text/markdown'].includes(file.type)) {
      setSelectedFile(file);
      setInputType('doc');
    } else {
      alert('Only PDF, TXT, and MD files are supported.');
      setSelectedFile(null);
    }
  }

  const handleSendMessage = async () => {
    if (!inputMessage.trim() && !selectedFile) return;

    if (showTips) setShowTips(false);

    const userMessage = selectedFile ? `File: ${selectedFile.name}` : inputMessage;

    const newMessages = [
      ...messages,
      {
        id: Date.now(),
        content: userMessage,
        sender: 'user',
      },
    ];
    setMessages(newMessages);
    setInputMessage('');
    setYtLink('');
    setSelectedFile(null);
    setIsLoading(true);

    try {
      const history = messages
        .slice(-3, -1)
        .map((m) => `${m.sender}: ${m.content}`)
        .join(', ');

      const formData = new FormData();
      formData.append('courseId', courseId);
      formData.append('userId', userId);
      formData.append('title', title);
      formData.append('history', `${chatSummary}\n${history}`);
      formData.append('context', context);
      formData.append('yt_link', ytLink);
      formData.append('input_type', inputType);

      if (selectedFile) {
        formData.append('files', selectedFile);
      } else {
        formData.append('input', inputMessage);
      }

      const { data } = await axios.post('/chat/ai', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setContext(data.data.data.context);
      setChatSummary(data.data.data.hisotry);
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          id: Date.now() + 1,
          content: data.data.output,
          sender: 'ai',
        },
      ]);
    } catch (error) {
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          id: Date.now() + 1,
          content: 'Sorry, there was an error processing your request.',
          sender: 'ai',
        },
      ]);
    } finally {
      setIsLoading(false);
      setInputType('chat');
    }
  };

  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  return (
    <div className="flex flex-col h-full rounded-xl">
      <Tips showTips={showTips} />
      <MessageArea messages={messages} isLoading={isLoading} bottomRef={bottomRef} />
      <div>
        <div className="space-y-2">
          <div className="flex items-center px-2 gap-3 rounded-xl border shadow-lg hover:shadow-xl">
            <Youtube className="w-5 h-5 text-gray-400 ml-2" />
            <input
              type="text"
              value={ytLink}
              onChange={(e) => {
                setYtLink(e.target.value);
                setInputType('yt_link');
              }}
              placeholder="Paste YouTube link here..."
              className="flex-1 px-2 py-2.5 bg-transparent outline-none"
            />
          </div>

          <div className="flex items-center px-2 gap-3 rounded-xl border shadow-lg hover:shadow-xl">
            <label htmlFor="file-upload" className="p-2 rounded-lg hover:bg-muted cursor-pointer">
              <Paperclip className="w-5 h-5 text-gray-400" />
              <input
                id="file-upload"
                type="file"
                accept=".pdf,.txt,.md"
                onChange={handleFileUpload}
                className="hidden"
              />
            </label>

            {selectedFile && <div className="text-sm text-gray-500">{selectedFile.name}</div>}

            <textarea
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
              placeholder="Ask a question about your document..."
              className="flex-1 px-2 py-3.5 bg-transparent outline-none overflow-auto"
            />

            <button
              onClick={handleSendMessage}
              disabled={isLoading}
              className="mr-2 p-2.5 rounded-lg bg-brand text-white hover:bg-brand-hover disabled:opacity-50">
              {isLoading ? (
                <Loader2 className="w-5 h-5 animate-spin" />
              ) : (
                <Send className="w-5 h-5" />
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentChat;
