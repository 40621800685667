import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { axios } from '../../services';
import img9 from '../../assets/comapnies/5.webp';
import { ScrollArea } from '../../components/ui/scroll-area';
import { Content, TableComponent, Project } from '../../components/dev/basket';

function Basket() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  const card = location.state?.card;

  const [projectBasket, setProjectBasket] = useState(null);

  const fetchProjectBasket = async () => {
    try {
      const { data } = await axios.get(`/basket/${id}`);
      setProjectBasket(data.data);
    } catch (error) {
      console.error('Error fetching project basket:', error);
    }
  };

  useEffect(() => {
    if (!card) {
      fetchProjectBasket();
    } else {
      setProjectBasket(card);
    }
  }, [id]);

  return (
    <ScrollArea className="h-full">
      <div className="flex flex-col md:flex-row m-4">
        <Content projectBasket={projectBasket} className="w-full md:w-1/4 mb-4 md:mb-0" />

        <div className="w-full md:w-3/4">
          <img
            src={img9}
            alt="Project Basket"
            className="h-[200px] w-full mb-6 object-cover rounded-xl"
          />

          <h1 className="text-2xl md:text-3xl font-bold mb-4">{projectBasket?.title}</h1>
          <p className="mb-6">{projectBasket?.description}</p>
          <TableComponent projectBasket={projectBasket} />

          {projectBasket?.projects?.map((project, index) => (
            <Project key={index} project={project} index={index} />
          ))}
        </div>
      </div>
    </ScrollArea>
  );
}

export default Basket;
