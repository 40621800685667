import { Route, Routes, Navigate } from 'react-router-dom';

import Home from './Home';
import Course from './Course';
import Courses from './Courses';
import Quiz from './Quiz/Start';
import Placement from './Placement';
import PlacementForm from './PlacementForm';
import ReadBlog from './ReadBlog';

import DsaRoadmap from './DSA/Roadmap';
import MIK75Sheet from './DSA/MIK75Sheet';
import DsaGraph from './DSA/DSAGraph';
import AiBuddy from './DSA/AiBuddy';

import Dev from './DEV/Dev';

import CreateBasket from './DEV/Create';
import Basket from './DEV/Basket';

import Success from './Success';

import Help from './Verification/Help';
import Policy from './Verification/Policy';
import ShippingDelivery from './Verification/Shipping';
import TermsConditions from './Verification/T&C';
import ReturnRefund from './Verification/Return';
import ContactUs from './Verification/Contact';
import Pricing from './Verification/Pricing';

function Pages() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate to="/home" replace />} />
        <Route path="/home" element={<Home />} />
        <Route path="/courses" element={<Courses />} />
        <Route path="/courses/:subjectName" element={<Course />} />
        <Route path="/courses/:subjectName/*" element={<Course />} />
        <Route path="/quiz" element={<Quiz />} />

        <Route path="/blogs" element={<Placement />} />
        <Route path="/blogs/:name" element={<ReadBlog />} />
        <Route path="/create-blogs" element={<PlacementForm />} />

        {/* <Route path="/dsa" element={<DsaRoadmap />} /> */}
        <Route path="/dsa" element={<MIK75Sheet />} />
        <Route path="/dsa/sheet" element={<DsaGraph />} />
        <Route path="/dsa/buddy" element={<AiBuddy />} />

        <Route path="/dev" element={<Dev />} />
        <Route path="/dev/create" element={<CreateBasket />} />
        <Route path="/dev/:id" element={<Basket />} />

        <Route path="/payment" element={<Success />} />

        <Route path="/help" element={<Help />} />
        <Route path="/privacy-policies" element={<Policy />} />
        <Route path="/shipping-delivery" element={<ShippingDelivery />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />
        <Route path="/return-refund" element={<ReturnRefund />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/pricing" element={<Pricing />} />
      </Routes>
    </>
  );
}

export default Pages;

export function PublicRoutes() {
  return (
    <Routes>
      <Route path="/help" element={<Help isStandalone={true} />} />
      <Route path="/privacy-policies" element={<Policy isStandalone={true} />} />
      <Route path="/terms-conditions" element={<TermsConditions isStandalone={true} />} />
      <Route path="/return-refund" element={<ReturnRefund isStandalone={true} />} />
      {/* <Route path="/pricing" element={<Pricing isStandalone={true} />} /> */}
    </Routes>
  );
}
