import React from 'react';
import { CardTitle, CardDescription } from '../../ui/card';
import { useTranslation } from 'react-i18next';

function Header() {
  const { t } = useTranslation();

  return (
    <div>
      <CardTitle className="text-3xl font-bold pb-2">{t('homePage.header.title')}</CardTitle>
      <CardDescription>{t('homePage.header.description')}</CardDescription>
    </div>
  );
}

export default Header;
