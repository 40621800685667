import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '../ui/accordion';

const Faq = () => {
  const { t } = useTranslation();
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    setPosition({ x, y });
  };

  const faqs = t('faq.questions', { returnObjects: true });

  return (
    <section id="faq" className="py-12 rounded-3xl bg-pastel-cream">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-2xl md:text-6xl font-bold text-pastel-green pb-2 mb-6">
          {t('faq.header')}
        </h2>
        <div className="flex flex-col md:flex-row gap-8">
          <div className="flex-1 ml-4">
            <Accordion className="space-y-2 " type="single" collapsible>
              {faqs.map((faq, index) => (
                <AccordionItem key={index} value={`faq-${index}`}>
                  <AccordionTrigger className="text-left md:text-xl font-semibold hover:no-underline">
                    {faq.question}
                  </AccordionTrigger>
                  <AccordionContent>
                    <p className="text-lg text-gray-700">{faq.answer}</p>
                  </AccordionContent>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
          <div className="flex-none w-full md:w-1/3 bg-pastel-white h-[250px] p-6 rounded-lg shadow-lg flex flex-col justify-center items-center text-center">
            <h3 className="text-2xl font-bold mb-4">{t('faq.cta.title')}</h3>
            <p className="text-lg mb-6">{t('faq.cta.description')}</p>
            <a
              href="#top"
              className="inline-block mx-auto px-6 py-3 bg-pastel-green text-white rounded-full font-semibold text-lg text-center transition-transform duration-300"
              onMouseMove={handleMouseMove}
              style={{
                transform: `translate(${(position.x - 50) / 10}px, ${(position.y - 25) / 10}px)`,
              }}>
              {t('faq.cta.button')}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;
