import React from 'react';
import { Skeleton } from '../ui/skeleton';

export const Loading = () => {
  return (
    <div className="mx-auto max-w-3xl flex flex-col items-center justify-center p-8 space-y-6 overflow-auto">
      <h1 className="text-xl font-semibold text-center bg-gradient-to-r from-blue-500 to-purple-500 bg-clip-text text-transparent">
        Generating topic material and searching for videos...
      </h1>

      <p className="text-md text-muted-foreground text-center mb-8">
        This may take a moment initially, but subsequent loads will be much faster.
      </p>

      <div className="relative max-w-3xl py-8">
        <div className="grid grid-cols-2 gap-4 mb-4">
          {[...Array(4)].map((_, index) => (
            <Skeleton key={index} className="h-[125px] w-[250px] rounded-xl" />
          ))}
        </div>

        <div className="space-y-2 w-full">
          <Skeleton className="h-4 w-full" />
          <Skeleton className="h-4 w-5/6" />
          <Skeleton className="h-4 w-3/4" />
        </div>
      </div>
    </div>
  );
};

export default Loading;
