import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ChevronRight } from 'lucide-react';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '../../components/ui/breadcrumb';

export default function BreadcrumbDemo() {
  const location = useLocation();
  const navigate = useNavigate();

  const pathnames = location.pathname.split('/').filter((crumb) => crumb !== '');

  const handleClick = (path) => {
    navigate(path);
  };

  return (
    <Breadcrumb className="overflow-hidden w-full">
      <BreadcrumbList className="flex items-center">
        {pathnames.map((crumb, index) => {
          const currentLink = `/${pathnames.slice(0, index + 1).join('/')}`;
          const title = decodeURIComponent(crumb);
          const isLast = index === pathnames.length - 1;

          return (
            <React.Fragment key={currentLink}>
              <BreadcrumbItem>
                {isLast ? (
                  <BreadcrumbPage className="text-gray-500">
                    {title.charAt(0).toUpperCase() + title.slice(1)}
                  </BreadcrumbPage>
                ) : (
                  <BreadcrumbLink onClick={() => handleClick(currentLink)}>
                    {title.charAt(0).toUpperCase() + title.slice(1)}
                  </BreadcrumbLink>
                )}
              </BreadcrumbItem>
              {!isLast && (
                <BreadcrumbSeparator>
                  <ChevronRight className="text-gray-500" />
                </BreadcrumbSeparator>
              )}
            </React.Fragment>
          );
        })}
      </BreadcrumbList>
    </Breadcrumb>
  );
}
