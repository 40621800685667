import * as React from 'react';
import { useState } from 'react';
import { Card, CardHeader, CardTitle, CardContent, CardFooter } from '../../ui/card';
import { Switch } from '../../ui/switch';
import { ChevronLeft, ChevronRight, Eye, EyeOff } from 'lucide-react';
import { Button } from '../../ui/button';
import ReactMarkdown from 'react-markdown';

const FlashCarousel = ({ flashCards, title }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [autoShowAnswers, setAutoShowAnswers] = useState(false);
  const [manualShowAnswer, setManualShowAnswer] = useState(false);

  console.log(flashCards);

  if (!flashCards || flashCards.length === 0) {
    return (
      <div className="text-center text-gray-600 p-6">
        No questions available. Please check back later.
      </div>
    );
  }

  const currentQuestion = flashCards[currentIndex];
  const isAnswerVisible = autoShowAnswers || manualShowAnswer;

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % flashCards.length);
    // Only reset manual visibility if auto-show is off
    if (!autoShowAnswers) {
      setManualShowAnswer(false);
    }
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + flashCards.length) % flashCards.length);
    // Only reset manual visibility if auto-show is off
    if (!autoShowAnswers) {
      setManualShowAnswer(false);
    }
  };

  const toggleAnswer = () => {
    // Only toggle if not in auto-show mode
    if (!autoShowAnswers) {
      setManualShowAnswer((prev) => !prev);
    }
  };

  const handleAutoShowChange = (checked) => {
    setAutoShowAnswers(checked);
    // Reset manual state when enabling auto-show
    if (checked) {
      setManualShowAnswer(false);
    }
  };

  return (
    <div className=" pb-20 md:mt-8">
      <Card className="border-none bg-pastel-cream dark:bg-pastel-green rounded-3xl p-1 md:p-8 md:max-w-[48rem] md:min-h-[32rem] mx-auto flex flex-col justify-between shadow-2xl">
        <CardHeader className="relative pb-4">
          <CardTitle className="text-base md:text-2xl font-bold text-brand dark:text-pastel-white">
            {title}
          </CardTitle>

          <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-32 h-0.5 bg-gradient-to-r from-pastel-white via-pastel-blue to-pastel-white" />
        </CardHeader>

        <CardContent className="px-6 pt-6 flex-1">
          <div className="space-y-4">
            <div className="flex items-center gap-4 text-sm md:text-base">
              <span className="flex-shrink-0 w-4 h-4 md:w-8 md:h-8 rounded-full bg-brand/40 text-pastel-white font-bold flex items-center justify-center">
                {currentIndex + 1}
              </span>
              <div className="font-semibold">{currentQuestion.question}</div>
            </div>

            <div
              className={`
              transform transition-all duration-500 ease-in-out
              ${
                isAnswerVisible
                  ? 'opacity-100 translate-y-0'
                  : 'opacity-0 -translate-y-4 pointer-events-none'
              }
            `}>
              <div className="bg-muted/20 rounded-xl p-6 border-l-4 border-brand">
                <div className="prose text-sm md:text-base">
                  <ReactMarkdown>{currentQuestion.ans}</ReactMarkdown>
                </div>
              </div>
            </div>
          </div>
        </CardContent>

        <CardFooter className="flex flex-col gap-2 md:gap-4">
          <Button
            onClick={toggleAnswer}
            variant="ghost"
            disabled={autoShowAnswers}
            className={`w-full max-w-xs mx-auto rounded-full bg-pastel-cream hover:bg-brand border border-brand text-brand-hover hover:text-brand-hover transition-all duration-300 
              ${autoShowAnswers ? 'opacity-50 cursor-not-allowed' : ''}`}>
            <div className="flex items-center gap-2 px-4 py-2">
              {isAnswerVisible ? (
                <>
                  <EyeOff className="w-4 h-4" />
                  <span>Hide Answer</span>
                </>
              ) : (
                <>
                  <Eye className="w-4 h-4" />
                  <span>Show Answer</span>
                </>
              )}
            </div>
          </Button>

          <div className="flex items-center justify-between w-full md:px-4 text-xs md:text-sm">
            <div className="bg-muted border-2 border-brand py-1 rounded-full text-brand px-2  font-medium">
              Question {currentIndex + 1} of {flashCards.length}
            </div>
            <div className="flex flex-col md:flex-row items-center md:gap-2">
              <Switch
                checked={autoShowAnswers}
                onCheckedChange={handleAutoShowChange}
                className="data-[state=checked]:bg-brand"
              />
              <p className="text-muted-foreground">Always show answers</p>
            </div>
            <div className="flex gap-1 md:gap-4">
              <Button
                onClick={handlePrev}
                variant="outline"
                className="rounded-full p-1 md:p-3 border-2 hover:bg-brand/20 transition-colors group">
                <ChevronLeft className="w-5 h-5 text-brand group-hover:scale-110 transition-transform" />
              </Button>

              <Button
                onClick={handleNext}
                variant="outline"
                className="rounded-full p-1 md:p-3 border-2 hover:bg-brand/20 transition-colors group">
                <ChevronRight className="w-5 h-5 text-brand group-hover:scale-110 transition-transform" />
              </Button>
            </div>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};

export default FlashCarousel;
