import React from 'react';
import { Pencil, Timer, Award, Bot } from 'lucide-react';
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from '../../ui/sheet';
import { Card, CardHeader, CardContent } from '../../ui/card';
import { timeAgo } from '../../../services';
import QuizPage from './QuizPage';

const QuizItem = ({ quiz }) => {
  const getDifficultyColor = (difficulty) => {
    const colors = {
      easy: 'text-green-500',
      medium: 'text-yellow-500',
      hard: 'text-red-500',
    };
    return colors[difficulty.toLowerCase()] || 'text-gray-500';
  };

  return (
    <Sheet>
      <SheetTrigger className="w-full h-full">
        <Card className="flex flex-col h-full group relative cursor-pointer hover:shadow-lg transition-all duration-300 border hover:border-brand-hover">
          {/* Gradient Overlay */}
          <div className="absolute inset-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none" />

          {/* Card Header */}
          <CardHeader className="p-6 space-y-2">
            <div className="flex justify-between items-start">
              <div>
                <h3 className="text-lg font-semibold group-hover:text-brand-hover transition-colors duration-200 line-clamp-2">
                  {quiz.title}
                </h3>
                <p className="text-sm text-gray-600 dark:text-gray-400">
                  {quiz.questions?.length || 0} questions
                </p>
              </div>

              {quiz.aiGenerated && (
                <div className="bg-gradient-to-r from-orange-600 to-purple-600 hover:from-orange-700 hover:to-purple-700 text-white rounded-full px-2 flex items-center justify-center">
                  <Bot className="size-6" />
                </div>
              )}
            </div>
          </CardHeader>

          {/* Card Content */}
          <CardContent className="p-6 border-t border-slate-200 dark:border-slate-700">
            <div className="text-sm space-y-2">
              <div className="flex items-center gap-2 text-gray-700 dark:text-gray-300">
                <Timer className="w-4 h-4" />
                <span>{quiz.length} min</span>
              </div>
              <div className="flex items-center gap-2 text-gray-700 dark:text-gray-300">
                <Award className="w-4 h-4" />
                <span className={getDifficultyColor(quiz.difficulty)}>{quiz.difficulty}</span>
              </div>
              <div className="flex items-center gap-2 text-gray-700 dark:text-gray-300">
                <Pencil className="w-4 h-4" />
                <span>{timeAgo(quiz.createdAt)}</span>
              </div>
            </div>
          </CardContent>

          {/* Hover Effect */}
          <div className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center justify-center bg-black/50 dark:bg-black/60">
            <button className="px-4 py-2 bg-brand text-white rounded-lg shadow hover:bg-brand-hover transition-all duration-200">
              Start Quiz
            </button>
          </div>
        </Card>
      </SheetTrigger>

      <SheetContent side="bottom" className="w-full min-w-full h-full">
        <SheetHeader>
          <SheetTitle>{quiz.title}</SheetTitle>
        </SheetHeader>
        <div className="h-full">
          <QuizPage questions={quiz.questions} />
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default QuizItem;
