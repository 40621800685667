import { PanelRight } from 'lucide-react';
import { createContext, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Separator } from '../components/ui/separator';
import { UserInfo } from './components';

import logo from '../assets/images/teal_icon.png';

export const SidebarContext = createContext();

export default function SideBar({ children }) {
  const [expanded, setExpanded] = useState(false);
  const location = useLocation();
  const value = {
    expanded,
    pathname: location.pathname,
  };

  return (
    <SidebarContext.Provider value={value}>
      <aside
        className={`sticky top-0 h-screen over transition-all z-10 ${expanded ? 'w-60' : 'w-12'}`}>
        <nav className="h-full flex flex-col border-r border-gray-500 shadow-sm items-center">
          <div
            className={`w-full px-2 py-1 flex justify-between gap-1 items-center ${
              !expanded && 'flex-col'
            }`}>
            <div>
              <Link to="/" className="flex items-center gap-2">
                <img
                  src={logo}
                  style={{
                    height: '32px',
                    width: '32px',
                    borderRadius: 8,
                  }}
                  alt="logo"
                />
                {expanded && (
                  <h2
                    className="font-thin text-xl text-pastel-green dark:text-pastel-white"
                    style={{
                      fontFamily: 'Finger Paint, serif',
                    }}>
                    Brogrammers
                  </h2>
                )}
              </Link>
            </div>

            <button
              onClick={() => setExpanded((curr) => !curr)}
              className="rounded-md hover:bg-muted p-2 items-center">
              <PanelRight className="size-4" />
            </button>
          </div>

          <Separator className="w-8" />

          <div className="flex-1 w-full mt-4">
            <ul className="w-full px-1 space-y-1 h-full">{children}</ul>
          </div>

          <div>
            {/* <CreateButton expanded={expanded} /> */}
            <UserInfo expanded={expanded} />
          </div>
        </nav>
      </aside>
    </SidebarContext.Provider>
  );
}
