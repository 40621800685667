import React from 'react';
import { Button } from '../../ui/button';
import { Alert, AlertDescription } from '../../ui/alert';
import { Trash2 } from 'lucide-react';
import { axios } from '../../../services';

const DeleteCourse = ({ course }) => {
  const [showConfirm, setShowConfirm] = React.useState(false);

  const handleDelete = async () => {
    try {
      await axios.delete(`/course/${course?.userId}/${course?._id}`);
      alert('Course successfully deleted.');
      window.location.href = '/';
    } catch (error) {
      alert('Error Deleting: ', error);
    }
  };

  return (
    <div className="flex flex-col space-y-4 bg-background p-6 rounded-lg">
      <div className="flex items-center justify-between">
        <div className="flex-1">
          <h3 className="text-lg font-medium text-red-600">Danger Zone</h3>
          <p className="mt-1 text-sm text-muted-foreground">
            Once you delete a course, there is no going back. Please be certain.
          </p>
        </div>
        {!showConfirm && (
          <Button
            variant="outline"
            size="sm"
            className="text-red-600 hover:text-red-700 hover:bg-red-50"
            onClick={() => setShowConfirm(true)}>
            <Trash2 className="h-4 w-4 mr-2" />
            Delete
          </Button>
        )}
      </div>

      {showConfirm && (
        <div className="bg-red-50/80 p-4 rounded-lg border-2 border-dashed border-red-200 space-y-4">
          <Alert variant="destructive">
            <AlertDescription className="space-y-2">
              <p className="font-medium">This will permanently delete:</p>
              <ul className="pl-6 space-y-1">
                <li>All course content and materials</li>
                <li>Student enrollment records</li>
                <li>Community chats and discussions</li>
                <li>Associated assignments and submissions</li>
              </ul>
              <p className="mt-2 font-medium">
                Are you absolutely sure you want to delete this course?
              </p>
            </AlertDescription>
          </Alert>

          <div className="flex items-center justify-end space-x-3 pt-2">
            <Button
              variant="outline"
              size="sm"
              onClick={() => setShowConfirm(false)}
              className="px-4">
              Cancel
            </Button>
            <Button variant="destructive" size="sm" onClick={handleDelete} className="px-4">
              Yes, delete this course
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DeleteCourse;
